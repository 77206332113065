
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { DisplayMedium, HeadingMedium, HeadingSmall, LabelLarge, LabelMedium, ParagraphSmall } from "baseui/typography";

import { Card, StyledBody } from "baseui/card";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";

import React, { useEffect, useState } from 'react';
export function LoadingComponent(props) {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(false);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (!show) {
        return (
            <FlexGridItem>

            <Card title={props.title} >
                <StyledBody >
                    <ParagraphSmall>
                       {props.description}
                    </ParagraphSmall>
                </StyledBody>
            </Card>
        </FlexGridItem>
        );
    }

    return (
        <FlexGridItem>

            <Card >
                <Skeleton/>
            </Card>
        </FlexGridItem>
    );
}
