import React, { useEffect, useState, useMemo } from 'react';
import { useUserAuth } from '../../util/auth/user-auth';
import { useAppContext } from '../../util/app/app-provider';
import { poster } from '../../util/requests/axios';
import {
  FormLabel,
  FormControl,
  Button,
  HStack,
  Box,
  Heading,
  Stack,
  Textarea,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import ResizeTextarea from 'react-textarea-autosize';
import { ResultComponent } from './result';
import debounce from 'lodash.debounce';
import { AvailableVariables } from './available-variables';
import { useHTTPRequestContext } from '../../util/errors/HTTPProvider';
import { Button as BaseButton } from 'baseui/button';
import { Select } from '@chakra-ui/react';

export function ForLoopBlock({ index }) {
  let { user } = useUserAuth();
  const { poster } = useHTTPRequestContext();
  let {
    blocks,
    setBlockRunResult,
    deleteBlock,
    setBlockData,
    addBlock,
    input,
    runRepeatBlocks,
  } = useAppContext();
  const result = blocks[index]?.result?.toString();

  let [counter, setCounter] = useState(1);

  return (
    <Box
      w={'full'}
      borderColor={'gray.200'}
      borderWidth={'1px'}
      rounded={'md'}
      alignItems="start"
      bg={'white'}
      transition={'all 0.19s ease-in-out'}
    >
      <Stack>
        <HStack
          w="full"
          align={'center'}
          justify={'space-between'}
          alignContent={'end'}
          bg={'gray.50'}
          rounded={'md'}
        >
          <HStack gap={'4px'}>
            <HStack
              padding="20px"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
                style={{ marginBottom: '5px' }}
              >
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                <path
                  fill-rule="evenodd"
                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                />
              </svg>
              <Heading
                textAlign="left"
                color={'black'}
                fontSize={'l'}
                fontFamily={'body'}
                // center vertically
              >
                {blocks[index].name}
              </Heading>
            </HStack>
            <HStack>
              <Box
                onClick={() => {
                  // disable text selection
                  if (counter > 1) setCounter(counter - 1);
                }}
                transition={'all 0.1s ease-in-out'}
                _hover={{ transform: 'scale(1.5)' }}
                style={{
                  cursor: 'pointer',
                  padding: '7px 0px 5px 5px',
                  borderRadius: '10px',
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                -
              </Box>
              <Box
                background={{ md: '#4cc9f0' }}
                style={{
                  cursor: 'pointer',
                  padding: '7px 5px 5px 5px',
                  borderRadius: '10px',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                <Text>
                  {counter} time{counter > 1 ? 's' : ''}
                </Text>
              </Box>
              <Box
                onClick={() => {
                  if (counter < 20) setCounter(counter + 1);
                }}
                transition={'all 0.1s ease-in-out'}
                _hover={{ transform: 'scale(1.5)' }}
                style={{
                  cursor: 'pointer',
                  padding: '7px 5px 5px 0px',
                  borderRadius: '10px',
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                +
              </Box>
            </HStack>
          </HStack>
          <HStack pr={'10px'}>
            <BaseButton
              size={'compact'}
              kind={'primary'}
              onClick={() => {
                runRepeatBlocks(index, counter);
              }}
            >
              Run
            </BaseButton>
            <BaseButton
              size="compact"
              kind={'tertiary'}
              onClick={() => {
                deleteBlock(index);
              }}
            >
              Delete
            </BaseButton>
          </HStack>
        </HStack>
      </Stack>
    </Box>
  );
}
