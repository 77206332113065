import { AppPage } from "../app";
import { AppContextProvider } from "../../util/app/app-provider";
import { useDisclosure } from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Heading,
    Stack,
    FormControl,
    FormLabel,
    HStack,
    Input,
} from '@chakra-ui/react'
import { ResultComponent } from "./result";
import {useFormik} from "formik";
import { useUserAuth } from "../../util/auth/user-auth";
import { useAppContext } from '../../util/app/app-provider';
import React, { useEffect, useState } from 'react';
import {useHTTPRequestContext} from "../../util/errors/HTTPProvider";
import { BlockTypes } from "../../util/constants";


export function AppModalBlock({index}) {
    const {
        isOpen: isExistingAppModalOpen,
        onOpen: onExistingAppModalOpen,
        onClose: onExistingAppModalClose,
    } = useDisclosure();
    const {
        isOpen: isNewAppModalOpen,
        onOpen: onNewAppModalOpen,
        onClose: onNewAppModalClose,
    } = useDisclosure();

    let [ modalAppId, setModalAppId ] = useState();

    let { user } = useUserAuth();
    let {
        blocks,
        setBlockRunResult,
        deleteBlock,
        setBlockData,
        input,
        } = useAppContext();
    const result = blocks[index]?.result;
    const {fetcher, poster} = useHTTPRequestContext();
    const existingAppFormik = useFormik({
        initialValues: {
            appId: '',
            appName: '',
        },
        onSubmit: async (values) => {
            // get existing app
            const existingApp = await fetcher(`/app/${user.id}/${values.appId}`);
            const appModalBlockData = {
                ...blocks[index],
                appId: existingApp.id,
            };
            setBlockData(index, appModalBlockData);
            setModalAppId(existingApp.id);
        }
    });

    const newAppFormik = useFormik({
        initialValues: {
            appId: '',
            appName: '',
        },
        onSubmit: async (values) => {
            // create new app
            const newApp = await poster(`/app/${user.id}/new`, {
                name: values.appName,
                blocks: [],
            });
            const appModalBlockData = {
                ...blocks[index],
                appId: newApp.id,
            };
            setBlockData(index, appModalBlockData);
            setModalAppId(newApp.id);
        }
    });

    const blockFormik = useFormik({
        initialValues: {
            iterations: 1
        },
        // TODO: run app for `values.iterations` and set blocks[index].result.runs
        onSubmit: async (values) => {
            if (!blocks[index].appId) {
                return;
            }
            // option 1: call run app by id > set block results
            // pros: 1 simple call
            // cons: need new implementation in backend

            // option 2: call get app by id > call run app in body > set block results
            const app = await fetcher(`/app/${user.id}/${blocks[index].appId}`);

            let blockRunResult = []
            for (let i=0; i<values.iterations; i++) {
                const result = await poster(`/app/${user.id}/run`, {
                    blocks: app.blocks,
                    input: {blocks},
                    iteration: i,
                });
                blockRunResult.push(result);
            }

            setBlockRunResult(BlockTypes.AppModal, index, blockRunResult);
            const appModalBlockData = {
                ...blocks[index],
                appId: blocks[index].appId,
                iterations: values.iterations,
            };
            setBlockData(index, appModalBlockData);
        }
    });

    // TODO: make sure appId and iterations is saved
    useEffect(() => {
        const appBlockData = {
            ...blocks[index],
        };
        setModalAppId(blocks[index].appId);
    }, []);

    return (
        <Box
            w={'full'}
            borderColor={'gray.200'}
            borderWidth={'1px'}
            rounded={'md'}
            alignItems='start'
            bg={'white'}>
            <Heading
                textAlign="left"
                padding="20px"
                color={'black'}
                fontSize={'l'}
                fontFamily={'body'}>
                {blocks[index].name}
            </Heading>
            <Stack spacing={5} padding="20px" bg={'white'} justifyContent={"space-between"} direction="column">
                {
                    modalAppId ?
                    (<Box>
                        <Box>
                            <FormControl>
                                <FormLabel>Iterations</FormLabel>
                                <Input id="iterations" defaultValue={1} value={blockFormik.values.iterations} onChange={blockFormik.handleChange}/>
                            </FormControl>
                        </Box>
                        <Box>
                            <Button size='md' mt={4} onClick={onExistingAppModalOpen}>
                                Open App
                            </Button>
                            <>
                            <Modal onClose={onExistingAppModalClose} size={'6xl'} isOpen={isExistingAppModalOpen}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>APP</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>

                                    <AppContextProvider declaredAppId={modalAppId} input={blocks}>
                                        <AppPage></AppPage>
                                    </AppContextProvider>

                                </ModalBody>
                                <ModalFooter>
                                <Button onClick={onExistingAppModalClose}>Close</Button>
                                </ModalFooter>
                            </ModalContent>
                            </Modal>
                            </>
                        </Box>
                        <Box>
                            <Button size='md' mt={4} onClick={() => setModalAppId(null)}>
                                Switch App
                            </Button>
                        </Box>
                    </Box>)
                    :
                    (<Box>
                        <Box>
                            <Button size='md' mt={4} onClick={onExistingAppModalOpen}>
                                Existing App
                            </Button>
                            <>
                            <Modal onClose={onExistingAppModalClose} size={'6xl'} isOpen={isExistingAppModalOpen}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>APP</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <FormControl>
                                        <FormLabel>App ID</FormLabel>
                                        <Input id="appId" value={existingAppFormik.values.appId}
                                            onChange = {(event) => {
                                                existingAppFormik.handleChange(event);
                                            }}/>
                                    </FormControl>
                                    <Button size='md' mt={4}
                                        onClick={
                                            () => {
                                                onExistingAppModalClose();
                                                existingAppFormik.submitForm();
                                            }
                                        }>Set</Button>
                                </ModalBody>
                                <ModalFooter>
                                <Button onClick={onExistingAppModalClose}>Close</Button>
                                </ModalFooter>
                            </ModalContent>
                            </Modal>
                            </>
                        </Box>

                        <Box>
                            <Button size='md' mt={4} onClick={onNewAppModalOpen}>
                                New App
                            </Button>
                            <>
                            <Modal onClose={onNewAppModalClose} size={'6xl'} isOpen={isNewAppModalOpen}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>APP</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <FormControl>
                                        <FormLabel>App Name</FormLabel>
                                        <Input id="appName" value={newAppFormik.values.appName}
                                            onChange = {(event) => {
                                                newAppFormik.handleChange(event);
                                            }}/>
                                    </FormControl>
                                    <Button size='md' mt={4}
                                        onClick={
                                            () => {
                                                onNewAppModalClose();
                                                newAppFormik.submitForm();
                                            }
                                        }>Set</Button>
                                </ModalBody>
                                <ModalFooter>
                                <Button onClick={onNewAppModalClose}>Close</Button>
                                </ModalFooter>
                            </ModalContent>
                            </Modal>
                            </>
                        </Box>
                    </Box>)
                }
                {
                    (() => {
                        const props = {
                            result,
                            index
                        }
                        return <ResultComponent {...props}/>
                    })()
                }
                <HStack justify={"flex-end"}>
                    <Button size='md' colorScheme={"messenger"}
                        onClick={
                            () => {
                                blockFormik.submitForm();
                            }
                        }>
                        Run
                    </Button>
                    <Button size='md' colorScheme={"red"}
                        onClick={
                            () => {
                                deleteBlock(index);
                            }
                        }>
                        Delete
                    </Button>
                </HStack>
            </Stack>
        </Box>
    )
}
