import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {Button} from 'baseui/button';
import {Heading, HeadingLevel} from 'baseui/heading';
import ChevronDown from 'baseui/icon/chevron-down';
import ChevronRight from 'baseui/icon/chevron-right';
import Overflow from 'baseui/icon/overflow';
import {StyledLink} from 'baseui/link';
import {StatefulMenu} from 'baseui/menu';
import {StatefulPopover, PLACEMENT} from 'baseui/popover';
import {withStyle, useStyletron} from 'baseui';
import {
    StyledTable,
    StyledHeadCell,
    StyledBodyCell,
} from 'baseui/table-grid';
import {Tag} from 'baseui/tag';
import {useAppContext} from "../util/app/app-provider";
import {useHTTPRequestContext} from "../util/errors/HTTPProvider";
import {Block} from "baseui/block";
import {FlexGrid, FlexGridItem} from "baseui/flex-grid";
import {LabelLarge, LabelSmall} from "baseui/typography";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {Link} from "baseui/link/styled-components";
function buildRow(deployment) {
    const runs = deployment?.AppDeploymentRuns?.map((run) => {
        return [run.id, new Date(run.createdAt), run.error]
    })

    return [
        deployment.id,
        new Date(deployment.createdAt),
        deployment.schedule,
        // deployment.status,
        runs,
    ];
}

function statusToTagKind(status) {
    switch (status) {
        case 'running': {
            return 'primary';
        }
        case 'passed': {
            return 'positive';
        }
        case 'failed': {
            return 'negative';
        }
        default: {
            return 'neutral';
        }
    }
}

function Tasks(props) {
    const [css] = useStyletron();
    return (
        <div
            className={css({
                gridColumn: 'span 3',
                padding: '32px 24px',
            })}
        >
            <StyledTable
                role="grid"
                $gridTemplateColumns="max-content auto auto"
            >
                <div role="row" className={css({display: 'contents'})}>
                    <StyledHeadCell $sticky={false}>ID</StyledHeadCell>
                    <StyledHeadCell $sticky={false}>Run at</StyledHeadCell>
                    <StyledHeadCell $sticky={false}>Error</StyledHeadCell>
                </div>
                {props.tasks.map((task, index) => {
                    return (
                        <div role="row" className={css({display: 'contents'})}>
                            <StyledBodyCell>{task[0]}</StyledBodyCell>
                            <StyledBodyCell>
                                {format(task[1], 'yyyy-MM-dd h:mm a')}
                            </StyledBodyCell>
                            <StyledBodyCell>
                                    {task[2]}
                            </StyledBodyCell>
                            {/*<StyledBodyCell>*/}
                            {/*    <StyledLink href={task[4]}>{task[3]}</StyledLink>*/}
                            {/*</StyledBodyCell>*/}
                        </div>
                    );
                })}
            </StyledTable>
        </div>
    );
}
const CenteredBodyCell = withStyle(StyledBodyCell, {
    display: 'flex',
    alignItems: 'center',
});

function Row({striped, row}) {
    const [css] = useStyletron();
    const [expanded, setExpanded] = React.useState(false);
    return (
        <div role="row" className={css({display: 'contents'})}>
            <CenteredBodyCell $striped={striped}>
                <Button
                    size="compact"
                    kind="tertiary"
                    onClick={() => setExpanded(!expanded)}
                    shape="square"
                >
                    {expanded ? (
                        <ChevronDown size={18} />
                    ) : (
                        <ChevronRight size={18} />
                    )}
                </Button>
                {row[0]}
            </CenteredBodyCell>
            <CenteredBodyCell $striped={striped}>
                {format(new Date(row[1]), 'yyyy-MM-dd h:mm a')}
            </CenteredBodyCell>
            <CenteredBodyCell $striped={striped}>
                {/*<Tag*/}
                {/*    closeable={false}*/}
                {/*    variant="outlined"*/}
                {/*    kind={statusToTagKind(row[2])}*/}
                {/*>*/}
                {/*    {row[2]}*/}
                {/*</Tag>*/}
                {row[2]}
            </CenteredBodyCell>
            {/*<CenteredBodyCell $striped={striped}>*/}
            {/*    <div*/}
            {/*        className={css({*/}
            {/*            textOverflow: 'ellipsis',*/}
            {/*            maxWidth: '200px',*/}
            {/*            overflow: 'hidden',*/}
            {/*            whiteSpace: 'nowrap',*/}
            {/*        })}*/}
            {/*    >*/}
            {/*        {row[5]}*/}
            {/*    </div>*/}
                {/*<StatefulPopover*/}
                {/*    placement={PLACEMENT.bottomLeft}*/}
                {/*    content={({close}) => (*/}
                {/*        <StatefulMenu*/}
                {/*            items={[*/}
                {/*                {label: 'Item One'},*/}
                {/*                {label: 'Item Two'},*/}
                {/*                {label: 'Item Three'},*/}
                {/*                {label: 'Item Four'},*/}
                {/*            ]}*/}
                {/*            onItemSelect={() => close()}*/}
                {/*            overrides={{*/}
                {/*                List: {style: {height: '144px', width: '138px'}},*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*>*/}
                {/*    <Button shape="square" kind="tertiary" size="compact">*/}
                {/*        <Overflow size={18} />*/}
                {/*    </Button>*/}
                {/*</StatefulPopover>*/}
            {/*</CenteredBodyCell>*/}
            {expanded && <Tasks tasks={row[3]} />}
        </div>
    );
}
export function DeploymentTwo() {
    const {
        appId,
        user,
        deployApp,
    } = useAppContext();
    const {fetcher} = useHTTPRequestContext();
    const [css,theme] = useStyletron()
    const [deployments, setDeployments] = useState();
    const [apiKey, setApiKey] = useState(null)
    const [cronSchedule, setCronSchedule] = useState('');
    // useEffect(() => {
    //     fetcher(`/settings/${user.id}/integrations`).then((integrations) => {
    //         const reduced = integrations.reduce((acc, currentValue) => {
    //             acc[currentValue.providerId] = {
    //                 secret: currentValue.key,
    //             }
    //             return acc;
    //         }, {});
    //         // console.log(reduced['API_KEY'])
    //         setApiKey(reduced['API_KEY']?.secret)
    //     })
    // }, [user]);

    useEffect(() => {
        fetcher(`/app/${user.id}/${appId}/apiDeployments`).then((deployments) => setDeployments(deployments));
    }, [])

    return (
        <Block>
            <Block>
                {/*<Block marginBottom={theme.sizing.scale800}>*/}
                {/*    /!*<FlexGrid flexGridColumnCount={2} flexGridColumnGap={theme.sizing.scale200} flexGridRowGap={theme.sizing.scale400}>*!/*/}
                {/*    /!*    <FlexGridItem>*!/*/}
                {/*    /!*        <LabelSmall>*!/*/}
                {/*    /!*            URL*!/*/}
                {/*    /!*        </LabelSmall>*!/*/}
                {/*    /!*    </FlexGridItem>*!/*/}
                {/*    /!*    <FlexGridItem>*!/*/}
                {/*    /!*        <LabelSmall>*!/*/}
                {/*    /!*            API Key: {apiKey}*!/*/}
                {/*    /!*        </LabelSmall>*!/*/}
                {/*    /!*    </FlexGridItem>*!/*/}
                {/*    /!*</FlexGrid>*!/*/}
                {/*</Block>*/}
                <Block display={'flex'} flexDirection={'column'} marginBottom={theme.sizing.scale1200}>
                    <FormControl
                        label={"When should we run this app? (describe in cron schedules)"}
                        caption={
                        <a>You can use our cron schedule generator
                            <Link href={"https://app.gptagent.com/share/4e377e0a"}> here </Link> to generate the input here</a>}
                    >
                        <Input value={cronSchedule} onChange={(e) => {
                            e.preventDefault()
                            const ev = e;
                            setCronSchedule(ev.target.value)
                        }}/>
                    </FormControl>
                    <Button onClick={() => deployApp(cronSchedule)} overrides={{
                        BaseButton: {
                            style: {
                                alignSelf: 'flex-end',
                                maxWidth: theme.sizing.scale3200,
                            }
                        }
                    }}>
                        Deploy now
                    </Button>
                </Block>
                <LabelLarge marginBottom={theme.sizing.scale400}>
                    Existing deployments
                </LabelLarge>
            <StyledTable
                $style={{
                    maxWidth: '100%'
                }}
                role="grid"

                $gridTemplateColumns="max-content auto auto"
            >
                <div role="row" className={css({display: 'contents'})}>
                    <StyledHeadCell>ID</StyledHeadCell>
                    <StyledHeadCell>Deployed at</StyledHeadCell>
                    <StyledHeadCell>Schedule</StyledHeadCell>
                    {/*<StyledHeadCell>Status</StyledHeadCell>*/}
                    {/*<StyledHeadCell>Last Run</StyledHeadCell>*/}
                    {/*<StyledHeadCell>Details</StyledHeadCell>*/}
                </div>
                {deployments?.map((deployment) => buildRow(deployment)).map((row, index) => {
                    const striped = index % 2 === 0;
                    return <Row key={index} row={row} striped={false} />;
                })}
            </StyledTable>
            </Block>
        </Block>
    );
}
