import {
    Box,
    Heading,
    Button,
    Text
} from '@chakra-ui/react';
import { useAppContext } from '../../util/app/app-provider';


export function EndRepeat ({ index }) {
    let {
        blocks,
        deleteBlock,
    } = useAppContext();
    return (
        <Box
            w={'full'}
            borderColor={'gray.200'}
            borderWidth={'1px'}
            rounded={'md'}
            alignItems='start'
            bg={'white'}
        >
            <Heading
                textAlign="left"
                padding="20px"
                color={'black'}
                fontSize={'l'}
                fontFamily={'body'}>
                {/* {blocks[index].name}
                 */}
                 End Repeat

            </Heading>
            {/* <Button size='md' colorScheme={"red"}
                            onClick={
                                () => {
                                    deleteBlock(index);
                                }
                            }>
                            Delete
                        </Button> */}
        </Box>
    );
}