import React from 'react';
import { Block } from 'baseui/block';
import { Button as BaseButton } from 'baseui/button';
import { useState } from 'react';
import { useAppContext } from '../util/app/app-provider.js';
import { HStack, Box, Heading, Stack, Text } from '@chakra-ui/react';

export function GeneralisedBlock({
  children,
  index,
  icon,
  onRun,
  isLoading,
  onClick,
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  let { blocks, deleteBlock } = useAppContext();
  const isSpecial =
    blocks[index].type === 'output' || blocks[index].type === 'input';

  return (
    <>
      <Text
        fontSize={'xs'}
        fontWeight={'bold'}
        color={'gray.500'}
        textTransform={'uppercase'}
        letterSpacing={'wide'}
        transform={'translateY(-5px)'}
      >
        {index}
      </Text>
      <Box
        // add index before the block type=
        w={'full'}
        // border
        borderColor={'gray.400'}
        borderWidth={'1px'}
        rounded={'md'}
        alignItems="start"
        transition={'all 0.19s ease-in-out'}
        onClick={onClick}

        // _hover={{
        //   cursor: 'pointer',
        //   transform: isCollapsed ? 'scale(1.02)' : 'scale(1)',
        //   shadow: 'md',
        // }}
      >
        <HStack
          justify={'space-between'}
          bg={isSpecial ? 'black' : 'white'}
          w={'full'}
          color={isSpecial ? 'white' : 'black'}
          transition={'all 0.19s ease-in-out'}
          borderRadius={'md'}
          _hover={{
            cursor: isSpecial ? 'default' : 
            
            'pointer',
            borderColor: 
            isSpecial ? 'black' :
            'gray.400',
            borderWidth:
            isSpecial ? '0px' :
            '1px',
            shadow: 
            isSpecial ? 'none' : 'md',

          }}
          onClick={() => {
            if (isSpecial) return;
            setIsCollapsed(!isCollapsed);
          }}
        >
          <HStack
            padding="20px"
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            {icon}
            <Heading
              textAlign="left"
              color={isSpecial ? 'white' : 'black'}
              fontSize={'l'}
              fontFamily={'body'}

              // center vertically
            >
              {blocks[index].name}
            </Heading>
          </HStack>
          <HStack justify={'flex-end'} pr={'10px'} borderRadius={'md'}>
            {onRun && (
              <BaseButton
                size={'compact'}
                kind={'primary'}
                isLoading={isLoading}
                onClick={onRun}
              >
                Run
              </BaseButton>
            )}

            {blocks[index].type !== 'input' &&
              blocks[index].type !== 'output' && (
                <BaseButton
                  size="compact"
                  kind={'tertiary'}
                  onClick={async () => {
                    await deleteBlock(index);
                  }}
                >
                  Delete
                </BaseButton>
              )}
          </HStack>
        </HStack>
        {/* children */}
        <Box display={isCollapsed ? 'none' : 'block'}>{children}</Box>
      </Box>
    </>
  );
}
