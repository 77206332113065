import { createClient } from '@supabase/supabase-js';


const projectUrl = 'https://lxzjxpsugzcuyozzubbk.supabase.co';
const projectPublicKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx4emp4cHN1Z3pjdXlvenp1YmJrIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njg2NzMzNTUsImV4cCI6MTk4NDI0OTM1NX0.3t5itfe_AyCxNj7Ekx2qal2MksAVAAwivCutIEWpHM4';
const options = {
    db: {
        schema: 'public',
    },
    auth: {
        autoRefreshToken: true,
        persistSession: true,
        detectSessionInUrl: true
    },
};
const supabase = createClient(projectUrl, projectPublicKey, options);

export default supabase;