export const BlockTypes = Object.freeze({
	EndIf: "end-if",
    IfElse: "if-else",
    LLM: "llm",
    HTTP: "http",
    WebCrawl: "web-crawl",
    WebSearch: "web-search",
    Code: "code",
    Discord: "discord",
    Slack: "slack",
    SQL: "sql",
    Output: "output",

    // front end exclusive block types
    Trigger: "trigger",
    Input: "input",
    AppModal: "app-modal",
    Repeat: "repeat",
    EndRepeat: "end-repeat",
})
