import {LabelMedium} from "baseui/typography";
import {Block} from "baseui/block";
import {useStyletron} from "baseui";
import {useCallback} from "react";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {useFormik} from "formik";
import {Button} from "@chakra-ui/react";
import {Textarea} from "baseui/textarea";
import {useAppContext} from "../util/app/app-provider";

export const AppSettings = () => {
    const {
        appName,
        updateAppName,
    } = useAppContext();

    const formik = useFormik({
        initialValues: {
            appName,
        },
        onSubmit: (values) => {
        console.log(values)
            updateAppName(values.appName)
    }
    })
    const [css, theme] = useStyletron();
    console.log(formik.touched)
    const inputOnChange = useCallback((event) => {
        event.preventDefault()
        formik.setFieldValue(event.target.id, event.target.value)
    }, [formik])

    return <Block $style={{
        display: 'flex',
        flexDirection: 'column',
        width: '800px',
        padding: theme.sizing.scale600,
    }}>
        <LabelMedium marginBottom={theme.sizing.scale1000}>App settings</LabelMedium>

                    <div className={css({
                        display: 'flex', flexDirection: 'column',
                    })}>
                        <FormControl
                            label="App name"
                        >
                            <Input id="appName" onBlur={formik.handleBlur} value={formik.values.appName} onChange={inputOnChange} required/>
                        </FormControl>
                    </div>
                    <div className={css({
                        display: 'flex', flexDirection: 'column',
                    })}>
                        <FormControl
                            label="App description"
                        >
                            <Textarea id="appDescription" onBlur={formik.handleBlur} value={formik.values.appDescription} onChange={inputOnChange} required/>
                        </FormControl>
                    </div>

                <div className={css({
                    maxWidth: '800px',
                    display: 'flex',
                    alignSelf: 'flex-end',
                    justifyContent: 'space-between',
                    border: 'none',
                })}>
                    <Button kind="primary" disabled={Object.keys(formik.touched).length == 0} onClick={formik.handleSubmit}>Update app</Button>
                </div>
    </Block>

}
