import { useUserAuth } from '../util/auth/user-auth';
import React, { useEffect, useState } from 'react';
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Stack,
  useColorModeValue,
  Textarea,
  Text,
  Heading,
  Toast,
} from '@chakra-ui/react';
import { poster } from '../util/requests/axios';
import { useNavigate } from 'react-router-dom';
import { useHTTPRequestContext } from '../util/errors/HTTPProvider';
import { Progress, Spinner} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';

const placeholders = [
  'An app that gets the latest events in your city and describes them in the writing style of William Shakespeare',
  'An app that looks up the weather in an area and generates a poem about it',
  'An app that takes as input, the name of an artist and generates a song by them',
];

export function CreateAppPage() {
  const toast = useToast();
  let navigate = useNavigate();
  let { user } = useUserAuth();
  let { fetcher } = useHTTPRequestContext();
  const [loaderValue, setLoaderValue] = useState(0);

  const [appName, setAppName] = useState(false);
  const [description, setDescription] = useState(false);
  const [openaiKey, setOpenaiKey] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);



  const handleAppNameChange = event => {
    setAppName(event.target.value);
  };
  const handleDescriptionChange = event => {
    setDescription(event.target.value);
  };
  const { poster } = useHTTPRequestContext();

  const fetchIntegrations = () => {
    fetcher(`/settings/${user.id}/integrations`).then(integrations => {
      const reduced = integrations.reduce((acc, currentValue) => {
        acc[currentValue.providerId] = {
          secret: currentValue.key,
        };
        return acc;
      }, {});
      setOpenaiKey(reduced.open_ai.secret);
    });
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex(placeholderIndex => (placeholderIndex + 1) % placeholders.length);
    }, 2500);
    return () => clearInterval(interval);
  }, []);



  const createApp = async () => {
    if (!appName) {
      toast({
        title: 'App name is required',
        status: 'error',
        duration: 4000,
      });
      return;
    }
    setLoading(true);
    // update loader every 0.5 seconds
    const interval = setInterval(() => {
      if (loaderValue === 100) {
        return;
      }
      setLoaderValue(loaderValue => (loaderValue + Math.random() * 5));
    }, 1000);
    const responseData = await poster(`/app/${user.id}/new`, {
      name: appName,
      description: description,
      blocks: [],
      secret: openaiKey,
    });
    navigate(`/apps/${responseData.id}`);
  };

  const createAppFromScratch = async () => {
    setLoading(true);
    const responseData = await poster(`/app/${user.id}/new`, {
      name: appName,
      blocks: [],
      secret: openaiKey,
    });
    navigate(`/apps/${responseData.id}`);
  };

  return (
    <Flex
      height={'80vh'}
      align={'center'}
      justify={'center'}
      direction={'column'}
      bg={useColorModeValue('white', 'white')}
    >
      {/* absolute box to show when creating app */}
      <Flex
        position={'absolute'}
        height={'100vh'}
        width={'100vw'}
        align={'center'}
        justify={'center'}
        direction={'column'}
        bg={useColorModeValue('white', 'white')}
        zIndex={10}
        transition={'all 0.5s ease'}
        opacity={isLoading ? 1 : 0}
        display={isLoading ? 'flex' : 'none'}
      >

        <Text fontSize={'4xl'} 
        style={{
          color: 'black',
          fontSize: '1.6rem',
          fontFamily: 'Inter',
          fontWeight: 'bold',
        }}
        >Creating app</Text>
        <Text>this will take about a minute. Please do not close this tab</Text>

        <Progress
          size="xs"
          value={loaderValue}
          colorScheme="green"
          width={'40%'}
          marginTop={'1rem'}
          style={{
              borderRadius: '15px',
          }}            
        />
        <Text

        style={{
          color: '#8C8C8C',
          fontSize: '0.9rem',
          fontFamily: 'Inter',
          marginTop: '1rem',
          display: loaderValue > 80 ? 'block' : 'none',
        }
        }
        >(looks like this is taking longer than expected. Hang tight!)</Text>
      </Flex>
      <Stack
        spacing={3}
        align={'center'}
        width={'40%'}
        background={'#F5F5F5'}
        borderRadius={'15px'}
        padding={'2rem 1.5rem 2rem 1.5rem'}
        border={'1px solid #DEDEDE'}
        shadow={'lg'}
      >
        <Heading
          style={{
            color: 'black',
            fontSize: '1.6rem',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          Create New App
        </Heading>
        <FormControl>
          <FormLabel placeholder="" margin={0}>
            App Name
          </FormLabel>
          <Input background={'white'} onChange={handleAppNameChange} />
        </FormControl>
        <FormControl paddingTop={'10px'}>
          <FormLabel margin={0}>What do you want your app to do?</FormLabel>
          <Textarea
            background={'white'}
            placeholder={placeholders[placeholderIndex]}
            resize={'none'}
            onChange={handleDescriptionChange}
          />
        </FormControl>
        <Button
          marginTop={'auto'}
          width={'100%'}
          background={'#00B086'}
          color={'white'}
          padding={'20px'}
          _hover={{
            background: '#00D2A0',
          }}
          h="1.75rem"
          size="lg"
          onClick={createApp}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner
              alignSelf={'center'}
              thickness="4px"
              speed="0.65s"
            ></Spinner>
          ) : (
            <Text>Create</Text>
          )}
        </Button>
        <Button
          marginTop={'auto'}
          width={'100%'}
          background={'#3182CE'}
          color={'white'}
          padding={'20px'}
          _hover={{
            background: '#63B3ED',
          }}
          h="1.75rem"
          size="lg"
          onClick={createAppFromScratch}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner
              alignSelf={'center'}
              thickness="4px"
              speed="0.65s"
            ></Spinner>
          ) : (
            <Text>Start From Scratch</Text>
          )}
        </Button>
      </Stack>
    </Flex>
  );
}
