import React, { useEffect, useState, useMemo } from 'react';
import { useUserAuth } from '../../util/auth/user-auth';
import { useAppContext } from '../../util/app/app-provider';
import { poster } from '../../util/requests/axios';
import {
  FormLabel,
  FormControl,
  Button,
  HStack,
  Box,
  Heading,
  Stack,
  Textarea,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import ResizeTextarea from 'react-textarea-autosize';
import { ResultComponent } from './result';
import debounce from 'lodash.debounce';
import { AvailableVariables } from './available-variables';
import { useHTTPRequestContext } from '../../util/errors/HTTPProvider';
import { Button as BaseButton } from 'baseui/button';
import { Select } from '@chakra-ui/react';
import { GeneralisedBlock } from '../GeneralisedBlock';
import { SelectBlockComponent } from '../prev_variables';
import { BlockTypes } from '../../util/constants';

export function IfElseBlock({ index }) {
  let { user } = useUserAuth();
  const [areVariablesVisible, setAreVariablesVisible] = useState(false);
  const { poster } = useHTTPRequestContext();
  let {
    blocks,
    setBlockRunResult,
    deleteBlock,
    setBlockData,
    addBlock,
    input,
  } = useAppContext();
  const result = blocks[index]?.result?.toString();
  let [block_index, setBlockIndex] = useState(0); //block_index holds the index of the last block that is a part of the if-else block

  const formik = useFormik({
    initialValues: {
      input: blocks[index]?.input,
    },
    onSubmit: async values => {
      // console.log(blocks);

      const blockRunResult = await poster(`/app/${user.id}/block/run`, {
        type: blocks[index].type,
        code: values.input,
        // access block results with [`${blockIndex}`]
        // scope: scope,
        scope: { blocks, input },
      });
      // convert to string

      setBlockRunResult(BlockTypes.IfElse, index, blockRunResult.toString());
      const ifBlockData = {
        ...blocks[index],
        ...formik.values,
      };
      setBlockData(index, ifBlockData);
    },
  });

  useEffect(
    e => {
      const ifBlockData = {
        ...blocks[index],
        ...formik.values,
      };
      setBlockData(index, ifBlockData);
    },
    [formik.values]
  );

  const setBlockDataAndSave = async () => {
    const ifBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    await setBlockData(index, ifBlockData);
  };

  // const [value1, setValue1] = useState("")
  // const [value2, setValue2] = useState("")
  // const [condition, setCondition] = useState("greater than")

  return (
    <GeneralisedBlock
      index={index}
      isLoading={formik.isSubmitting}
      onRun={formik.submitForm}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          // style margin bottom 5px
          style={{
            marginBottom: '5px',
          }}
        >
          <path d="m1.854 14.854 13-13a.5.5 0 0 0-.708-.708l-13 13a.5.5 0 0 0 .708.708ZM4 1a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 4 1Zm5 11a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5A.5.5 0 0 1 9 12Z" />
        </svg>
      }
    >
      <Stack width={'100%'} direction="row" padding="20px">
        <Stack
          spacing={5}
          bg={'white'}
          justifyContent={'space-between'}
          direction="column"
          width={'100%'}
        >
          {/* <AvailableVariables index={index} /> */}
          <FormControl>
            <FormLabel>JS Eval Condition</FormLabel>{' '}
            <Textarea
              as={ResizeTextarea}
              fontFamily={'monospace'}
              id="input"
              placeholder='blocks[0].result === "hello world"'
              onChange={event => {
                formik.handleChange(event);
              }}
              onClick={() => {
                setAreVariablesVisible(true);
              }}
              value={formik.values.input}
            />
            {areVariablesVisible && (
                <SelectBlockComponent index={index} blocks={blocks} onHide={
                  () => {
                    setAreVariablesVisible(false);
                  }
                }/>
              )}
          </FormControl>
          {/* input box */}
          {/* <Stack
                        direction={'row'} 
                        >
                            <Input
                                value={value1}
                                onChange={(e) => {
                                    setValue1(e.target.value)
                                }}
                            />
                              <Select placeholder={condition} size='sm' height={'40px'} 
                               >
                              <option 
                              onClick={() => {
                                setCondition("greater than")
                                }}
                              >greater than</option>
                              <option value='option1'
                              onClick={() => {
                                setCondition("less than")
                                }}
                              >less than</option>
                            <option value='option2'
                            
                            onClick={() => {
                                setCondition("equal to")
                                }}
                                >equal to</option>
                                </Select>
                            <Input
                                value={value2}
                                onChange={(e) => {
                                    setValue2(e.target.value)
                                }}
                            />
                        </Stack> */}
        </Stack>
        <Box width={'100%'}>
          <ResultComponent {...{ result, index }} />
        </Box>
      </Stack>
    </GeneralisedBlock>
  );
}
