import React, { useEffect, useState, useMemo } from 'react';
import { useUserAuth } from '../../util/auth/user-auth';
import { useAppContext } from '../../util/app/app-provider';
import {
  FormLabel,
  FormControl,
  Box,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import ResizeTextarea from 'react-textarea-autosize';
import { ResultComponent } from './result';
import debounce from 'lodash.debounce';
import { AvailableVariables } from './available-variables';
import { useHTTPRequestContext } from '../../util/errors/HTTPProvider';
import { GeneralisedBlock } from '../GeneralisedBlock';
import { SelectBlockComponent } from '../prev_variables';
import { BlockTypes } from '../../util/constants';

export function SqlBlock({ index }) {
  function executeBlock() {
    formik.submitForm();
  }
  const [areVariablesVisible, setAreVariablesVisible] = useState(false);

  let { user } = useUserAuth();
  let {
    blocks,
    setBlockRunResult,
    deleteBlock,
    setBlockData,
    input
  } = useAppContext();
  const result = blocks[index]?.result;
  const { poster } = useHTTPRequestContext();
  const formik = useFormik({
    initialValues: {
      query: blocks[index]?.query,
    },
    onSubmit: async values => {
      const blockRunResult = await poster(`/app/${user.id}/block/run`, {
        type: blocks[index].type,
        query: values.query,
        // access block results with [`${blockIndex}`]
        // scope: scope,
        scope: { blocks, input },
      });
      setBlockRunResult(BlockTypes.SQL, index, blockRunResult);

      const codeBlockData = {
        ...blocks[index],
        ...formik.values,
      };
      setBlockData(index, codeBlockData);
    },
  });

  useEffect(() => {
    const codeBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, codeBlockData);
  }, [formik.values]);

  const setBlockDataAndSave = async () => {
    const codeBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    await setBlockData(index, codeBlockData);
  };

  return (
    <GeneralisedBlock
      index={index}
      isLoading={formik.isSubmitting}
      onRun={formik.handleSubmit}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          style={{ marginBottom: '5px' }}
        >
          <path d="M2.114 8.063V7.9c1.005-.102 1.497-.615 1.497-1.6V4.503c0-1.094.39-1.538 1.354-1.538h.273V2h-.376C3.25 2 2.49 2.759 2.49 4.352v1.524c0 1.094-.376 1.456-1.49 1.456v1.299c1.114 0 1.49.362 1.49 1.456v1.524c0 1.593.759 2.352 2.372 2.352h.376v-.964h-.273c-.964 0-1.354-.444-1.354-1.538V9.663c0-.984-.492-1.497-1.497-1.6zM13.886 7.9v.163c-1.005.103-1.497.616-1.497 1.6v1.798c0 1.094-.39 1.538-1.354 1.538h-.273v.964h.376c1.613 0 2.372-.759 2.372-2.352v-1.524c0-1.094.376-1.456 1.49-1.456V7.332c-1.114 0-1.49-.362-1.49-1.456V4.352C13.51 2.759 12.75 2 11.138 2h-.376v.964h.273c.964 0 1.354.444 1.354 1.538V6.3c0 .984.492 1.497 1.497 1.6z" />
        </svg>
      }
    >
      <Stack width={'100%'} direction="row" padding="20px">
        <Stack
          spacing={5}
          bg={'white'}
          justifyContent={'space-between'}
          direction="column"
          width={'100%'}
        >
          <FormControl>
            <FormLabel>SQL Query</FormLabel>
            <Textarea
              as={ResizeTextarea}
              fontFamily={'monospace'}
              id="query"
              placeholder="SELECT * FROM Users;"
              onClick={() => {
                setAreVariablesVisible(true);
              }}
              onChange={event => {
                formik.handleChange(event);
              }}
              value={formik.values.query}
            />
            {areVariablesVisible && (
                <SelectBlockComponent index={index} blocks={blocks} onHide={
                  () => {
                    setAreVariablesVisible(false);
                  }
                }/>
              )}

          </FormControl>
        </Stack>
        <Box width={'100%'}>
          <ResultComponent {...{ result, index }} />
        </Box>
      </Stack>
    </GeneralisedBlock>
  );
}
