import { React, useEffect, useState } from 'react';
import {
  FormLabel,
  FormControl,
  Textarea,
  Box,
  Text,
  Flex,
  useToast,
  HStack,
} from '@chakra-ui/react';
import ReactJson from 'react-json-view';
import ResizeTextarea from 'react-textarea-autosize';
import { Block } from 'baseui/block';
import { Stack } from '@chakra-ui/react';
import { Toast } from 'baseui/toast';
import { namespaceToTemplateVariable } from '../../util/namespace';
// import blocks
import { useAppContext } from '../../util/app/app-provider.js';
import { Navigate } from 'react-router-dom';

export function ResultComponent({ result, index }) {
  const { blocks, setExpandResult } = useAppContext();
  const [isIconVisible, setIsIconVisible] = useState(false);

  //lookup table of blocks and their most relevant keys in each index
  const relevantKeys = {
    llm: ['result'],
    'web-search': ['result', 'search_results', '_', '_'],
    input: ['input'],
    output: ['result'],
    'web-crawl': ['result', 'data', '_', 'results', '_', 'text,html'],
    code: ['result'],
  };

  const toast = useToast();

  Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    var a = s.split('.');
    try {
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      }
    } catch (e) {
      return;
    }

    return o;
  };

  // lookup table of blocks and their most relevant starting path
  const startingPaths = {
    'web-crawl':
      typeof blocks[index]?.result === 'object'
        ? `${index}.result.data.0.results.0`
        : `${index}`,
    'web-search':
      typeof blocks[index]?.result === 'object'
        ? `${index}.result.search_results`
        : `${index}`,
  };

  const [selectedBlockPath, setSelectedBlockPath] = useState(
    // check if result is null or undefined
    result === undefined
      ? `${index}`
      : startingPaths[blocks[index].type.toString()] || `${index}`
  );

  useEffect(() => {
    setSelectedBlockPath(
      result === undefined
        ? `${index}`
        : startingPaths[blocks[index].type.toString()] || `${index}`
    );
  }, [result]);

  if (index === 0) {
    return <></>;
  }
  return (
    <>
      <Text>Results</Text>
      <Box
        width={'100%'}
        // height={'100%'}
        bg={'white'}
        borderRadius={'md'}
        border={'1px solid #e2e8f0'}
        zIndex={'100'}
        padding={'10px'}
        overflowY={'auto'}
        maxHeight={'300px'}
        marginBottom={'20px'}
        // shadow
      >
        <Flex gap={2} wrap={'wrap'} direction={'column'}>
          {/* if Object?.byString(blocks, selectedBlockPath + '.' + key) is undefined return nothing */}

          {!selectedBlockPath ? (
            <>
              <HStack width={'full'}>
                <Text
                  fontFamily={'monospace'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                >
                  Results:
                </Text>
              </HStack>
              <HStack
                width={'full'}
                height={'1px'}
                bg={'#e2e8f0'}
                marginBottom={'10px'}
              ></HStack>
            </>
          ) : (
            <></>
          )}
          {
            <>
              <HStack width={'full'}>
                <Text
                  onClick={() => {
                    if (selectedBlockPath === `[${index}]`) {
                      return;
                    }

                    // remove the last part of the path
                    let newSelectedBlockPath = selectedBlockPath
                      .split('.')
                      .slice(0, -1)
                      .join('.');

                    setSelectedBlockPath(newSelectedBlockPath);
                    // console.log(selectedBlockPath);
                  }}
                  fontFamily={'monospace'}
                  fontSize={'14px'}
                  padding={'5px'}
                  // underline
                  textDecoration={'underline'}
                  style={{ cursor: 'pointer' }}
                >
                  {selectedBlockPath === `[${index}]` ||
                  selectedBlockPath === index.toString() ? (
                    <></>
                  ) : (
                    '< ' + selectedBlockPath.split('.').slice(-1).join('')
                  )}
                </Text>
              </HStack>
            </>
          }

          {selectedBlockPath ? (
            Object.keys(Object.byString(blocks, selectedBlockPath))?.map(
              (key, i) => {
                // if the key is in the relevant keys for the block type
                if (relevantKeys[blocks[index].type] !== undefined) {
                  let intermediateIndex =
                    selectedBlockPath.split('.').length - 1;

                  if (
                    !relevantKeys[blocks[index].type][
                      intermediateIndex
                    ].includes(key)
                  ) {
                    // if not _
                    if (
                      relevantKeys[blocks[index].type][intermediateIndex] !==
                      '_'
                    ) {
                      return <></>;
                    }
                  }
                }
                if (!Object.byString(blocks, selectedBlockPath + '.' + key)) {
                  return <></>;
                }

                return (
                  <Box
                    key={i}
                    bg={'white'}
                    borderRadius={'md'}
                    border={'1px solid #e2e8f0'}
                    fontSize={'sm'}
                    _hover={
                      {
                        // cursor: 'pointer',
                      }
                    }
                    onMouseEnter={() => {
                      setIsIconVisible(true);
                    }}
                    onMouseLeave={() => {
                      setIsIconVisible(false);
                    }}
                      onClick={() => {
                      if (
                        typeof Object.byString(
                          blocks,
                          selectedBlockPath + '.' + key
                        ) === 'object'
                      ) {
                        setSelectedBlockPath(selectedBlockPath + '.' + key);
                      } else {
                        toast({
                          title: 'Reference copied to clipboard',
                          position: 'top',
                        });

                        let newBlockPath = selectedBlockPath + '.' + key;
                        newBlockPath =
                          '${blocks' +
                          newBlockPath.replace(/(\.?)(\d+)\./g, '[$2].') +
                          '}';
                        navigator.clipboard.writeText(newBlockPath);
                        return;
                      }
                    }}
                  >
                    <Box
                      // align right
                      display={'flex'}
                      width={'full'}
                      justifyContent={'end'}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      style={{ stroke: 'black' }}
                      opacity={isIconVisible ? 1 : 0}
                      transition={'opacity 0.1s ease-in-out'}

                    >
                      <Box padding={'5px'}
                      margin={'5px'}
                      stroke={'#e2e8f0'}
                      bg={'#e2e8f0'}
                      borderRadius={'md'}

                      position={'absolute'}
                      onClick={() => {
                        console.log('clicked');
                        setExpandResult(
                          JSON.stringify(
                            Object.byString(
                              blocks,
                              selectedBlockPath + '.' + key
                            ).trim()
                          )
                        );
                      }}
                      style={{ cursor: 'pointer'
                    , stroke: 'black'
                    
                    }}
                      >
                        <svg
                          
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z"
                          />
                        </svg>
                      </Box>
                    </Box>

                    {typeof Object.byString(
                      blocks,
                      selectedBlockPath + '.' + key
                    ) === 'object' ? (
                      <Text fontWeight={'bold'} padding={'5px'}>
                        {JSON.stringify(
                          Object.byString(blocks, selectedBlockPath + '.' + key)
                        ).length > 100
                          ? JSON.stringify(
                              Object.byString(
                                blocks,
                                selectedBlockPath + '.' + key
                              )
                            ).slice(0, 200) + '...'
                          : JSON.stringify(
                              Object.byString(
                                blocks,
                                selectedBlockPath + '.' + key
                              )
                            )}
                      </Text>
                    ) : (
                      <Text fontWeight={'bold'} padding={'5px'}>
                        {Object?.byString(blocks, selectedBlockPath + '.' + key)
                          .length > 100 ? (
                          <Text>
                            {Object?.byString(
                              blocks,
                              selectedBlockPath + '.' + key
                            ).slice(0, 200) + '...'}
                          </Text>
                        ) : (
                          Object?.byString(
                            blocks,
                            selectedBlockPath + '.' + key
                          )
                        )}
                      </Text>
                    )}
                    <Text
                      bg={'gray.100'}
                      padding={'3px 5px'}
                      fontSize={'10px'}
                      fontFamily={'monospace'}
                    >
                      {/* {'blocks' + selectedBlockPath + (isNaN(key) ? ('.' + key) : ('[' + key + ']'))}
                       */}
                      {key}
                    </Text>
                  </Box>
                );
              }
            )
          ) : (
            <></>
          )}
        </Flex>
      </Box>
    </>
  );
}
