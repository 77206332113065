import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../../util/auth/user-auth';
import { useAppContext } from '../../util/app/app-provider';
import { Button as BaseButton } from 'baseui/button';
import { poster } from '../../util/requests/axios';
import {
  Input,
  Button,
  Box,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  HStack,
  Textarea,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import Mustache from 'mustache';
import ResizeTextarea from 'react-textarea-autosize';
import { ResultComponent } from './result';
import { AvailableVariables } from './available-variables';
import { interpolate } from '../../util/interpolate';
import { useHTTPRequestContext } from '../../util/errors/HTTPProvider';
import { GeneralisedBlock } from '../GeneralisedBlock';
import { SelectBlockComponent } from '../prev_variables';
import { BlockTypes } from '../../util/constants';

export function SearchBlock({ index }) {
  const [areVariablesVisible, setAreVariablesVisible] = useState(false);
  let { user } = useUserAuth();
  const { poster } = useHTTPRequestContext();

  let { blocks, setBlockRunResult, deleteBlock, setBlockData, input } =
    useAppContext();
  const result = blocks[index].result;

  const pruneSearchResult = res => {
    // console.log(res.body);
    let body = JSON.parse(res.body);
    return {
      search_results: body.organic_results.map(r => {
        return {
          link: r.link,
          title: r.title,
          snippet: r.snippet,
        };
      }),
    };
  };

  const formik = useFormik({
    initialValues: {
      engine: 'google',
      query: blocks[index]?.query,
    },
    onSubmit: async values => {
      const blockRunResult = await poster(`/app/${user.id}/block/run`, {
        type: blocks[index].type,
        engine: 'google',
        query: interpolate(values.query, { blocks, input }),
      });
      setBlockRunResult(BlockTypes.WebSearch, index, pruneSearchResult(blockRunResult));
    },
  });

  useEffect(() => {
    const searchBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, searchBlockData);
  }, [formik.values]);

  const setBlockDataAndSave = async () => {
    const searchBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, searchBlockData);
  };

  return (
    <GeneralisedBlock
      index={index}
      onRun={formik.handleSubmit}
      isLoading={formik.isSubmitting}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          style={{ marginBottom: '5px' }}
        >
          <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
        </svg>
      }
    >
      <Stack padding={'10px'}>
        <Stack width={'100%'} direction="row">
          <Stack
            spacing={5}
            padding="20px"
            bg={'white'}
            justifyContent={'space-between'}
            direction="column"
            width={'100%'}
          >
            {/* <AvailableVariables index={index} /> */}
            {/* <FormControl>
                        <FormLabel>Engine</FormLabel>
                        <Input
                            id="engine"
                            placeholder='Engine'
                            onChange={(event) => {
                                formik.handleChange(event);
                            }}
                            value={formik.values.engine}
                        />
                    </FormControl> */}
            <FormControl>
              <FormLabel>Query</FormLabel>
              <Textarea
                as={ResizeTextarea}
                id="query"
                placeholder="Query"
                onChange={event => {
                  formik.handleChange(event);
                }}
                onClick={() => {
                  setAreVariablesVisible(true);
                }}

                value={formik.values.query}
              />
              {areVariablesVisible && (
              <SelectBlockComponent
                index={index}
                blocks={blocks}
                onHide={() => {
                  setAreVariablesVisible(false);
                }}
              />
            )}
            </FormControl>
          </Stack>
          <Box width={'100%'}>
            <ResultComponent {...{ result, index }} />
          </Box>
        </Stack>
      </Stack>
    </GeneralisedBlock>
  );
}
