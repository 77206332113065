import {Accordion, Panel, StatelessAccordion} from "baseui/accordion";
import {UIDeploy} from "./ui-deploy";
import {DeploymentTwo} from "./deployment-two";
import React from "react";
import {Text} from "@chakra-ui/react";
import {HeadingMedium, LabelMedium, ParagraphMedium} from "baseui/typography";
import {StyledDivider} from "baseui/divider";
import {useStyletron} from "baseui";
import {DisordDeployment} from "./disord-deployment";

export const DeploymentMaster = () => {
    const [expanded, setExpanded] = React.useState([]);
    const [css, theme] = useStyletron();
    return (
        <>
            <div className={css({
                marginBottom: theme.sizing.scale800
            })}>
        <HeadingMedium>
            Deployments
        </HeadingMedium>

    <ParagraphMedium>
        Deploy your apps to the world at the click of a
        button
    </ParagraphMedium>
            </div>
            <LabelMedium marginBottom={theme.sizing.scale300}>
                How do you want to deploy this app?
            </LabelMedium>
        <StatelessAccordion
            expanded={expanded}
            onChange={({key, expanded}) => {

                setExpanded(expanded);
            }}
            accordion
        >
            <Panel title="Deploy to an interface"><UIDeploy /></Panel>
            <Panel title="Run on a schedule"><DeploymentTwo /></Panel>
            <Panel title="Run using a discord bot command"><DisordDeployment /></Panel>
        </StatelessAccordion>
        </>
    )
}
