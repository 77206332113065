import { createContext, useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUserAuth } from '../auth/user-auth';
import debounce from 'lodash.debounce';
import { useHTTPRequestContext } from '../errors/HTTPProvider';
import { BlockTypes } from '../constants';
import { useToast } from '@chakra-ui/react';

import posthog from "posthog-js";

let AppContext = createContext(null);

export function AppContextProvider({ children, input, declaredAppId }) {
  let { appId } = useParams();
  // get a functional app component without routing
  if (declaredAppId) {
    appId = declaredAppId;
  }
  let { user } = useUserAuth();
  const [appName, setAppName] = useState();
  const [appDescription, setAppDescription] = useState();
  const [appCreatorUserId, setAppCreatorUserId] = useState();
  const [isTemplate, setIsTemplate] = useState(false);
  const [lastSavedAt, setLastSavedAt] = useState(null);
  const { fetcher, poster, patcher } = useHTTPRequestContext();
  const [expandResult, setExpandResult] = useState('');
  const [blocks, setBlocks] = useState([]);
  const toast = useToast();

  useEffect(() => {
    if (user) {
      fetcher(`/app/${user.id}/${appId}`).then(app => {
        setAppName(app.name);
        setBlocks(app.blocks);
        setIsTemplate(app.is_template);
        setAppCreatorUserId(app.userId);
      });
    }
  }, [user]);

  const _save = async blocks => {
    if (appCreatorUserId !== user.id) return;
    setLastSavedAt(new Date());
    const responseData = await patcher(`/app/${user.id}/${appId}`, {
      name: appName,
      blocks: blocks,
    });
  };

  const debouncedSaveBlocks = debounce(_save, 15000);

  useEffect(() => {
    // console.log(blocks);
    if (blocks.length > 0) {
      // if not lastSavedAt then save
      if (!lastSavedAt) {
        _save(blocks);
        setLastSavedAt(new Date());
      } else {
        // if lastSavedAt is more than 7.5 seconds ago then save
        if (new Date() - lastSavedAt > 7500) {
          _save(blocks);
          setLastSavedAt(new Date());
        }
      }
    }
  }, [blocks]);

  const setBlockData = async (index, inputJson) => {
    setBlocks(oldState => {
      let newBlocks = [...oldState];
      newBlocks[index] = {
        ...newBlocks[index],
        ...inputJson,
      };
      // debouncedSaveBlocks(newBlocks)
      return newBlocks;
    });
  };

  const setBlockRunResult = async (type, index, blockRunResult) => {
    setBlocks(blocks => {
      let newBlocks = [...blocks];
      let result;

      try {
        result = JSON.parse(blockRunResult.body);
      } catch (e) {
        if (blockRunResult.body && type != 'http') result = blockRunResult.body;
        else result = blockRunResult;
      }
      newBlocks[index].result = result;

      return newBlocks;
    });

    _save(blocks);
  };

  const deleteBlock = async index => {
    // console.log('delete block at index', index);
    // console.log('blocks before delete', blocks);
    // if block at that index is if-else delete the end-if block
    if (blocks[index].type === 'if-else') {
      // search for end-if block after index
      let endIfIndex = blocks.findIndex((block, i) => {
        if (i > index && block.type === 'end-if') return true;
      });
      // delete end-if block
      deleteBlock(endIfIndex);
    }
    // if block at that index is repeat delete the end-if block
    if (blocks[index].type === 'repeat') {
      // search for end-if block after index
      let endRepeatIndex = blocks.findIndex((block, i) => {
        if (i > index && block.type === 'end-repeat') return true;
      });
      // delete end-if block
      deleteBlock(endRepeatIndex);
    }

    setBlocks(oldBlocks => {
      const newBlocks = [...oldBlocks];
      newBlocks.splice(index, 1);
      return newBlocks;
    });

    // console.log('blocks after delete', blocks);
  };

  const betterDeleteBlock = async index => {
    // console.log('delete block at index', index);
    // console.log('blocks before delete', blocks);
    setBlocks(oldBlocks => {
      const newBlocks = [...oldBlocks];
      newBlocks.splice(index, 1);
      return newBlocks;
    });
    await _save(blocks);
    // console.log('blocks after delete', blocks);
  };

  // add optional variable to function input

  const addTrigger = () => {
    setBlocks(oldBlocks => {
      const newBlocks = [...oldBlocks];
      newBlocks.splice(0, 0, {
        type: 'trigger',
        name: 'Trigger',
        indentLevel: 0,
      });
      return newBlocks;
    });
  };

  const addBlockRedesigned = (blockType, data) => {
    let index = data?.index;
    if (index === undefined) index = blocks.length - 1;

    let indentLevel = data?.indentLevel;

    // adds block with type event.target.value amd name event.target.name after index
    setBlocks(oldBlocks => {
      const newBlocks = [...oldBlocks];
      newBlocks.splice(index + 1, 0, {
        type: blockType.value,
        name: blockType.name,
        indentLevel: indentLevel,
      });
      return newBlocks;
    });

    // if event.target.value is if-else add another end-if block after it
    if (blockType.value === 'if-else') {
      // add block after index with type end-if
      setBlocks(oldBlocks => {
        const newBlocks = [...oldBlocks];
        newBlocks.splice(index + 2, 0, {
          type: 'end-if',
          name: 'end-if',
          indentLevel: indentLevel,
        });
        return newBlocks;
      });
      _save(blocks);
    }

    // if event.target.value is repeat add another end repeat block after it
    if (blockType.value === 'repeat') {
      // add block after index with type end-if
      setBlocks(oldBlocks => {
        const newBlocks = [...oldBlocks];
        newBlocks.splice(index + 2, 0, {
          type: 'end-repeat',
          name: 'End Repeat',
          indentLevel: indentLevel,
        });
        return newBlocks;
      });
    }
  };

  const addBlock = (event, data) => {
    let index = data?.index;
    if (index === undefined) index = blocks.length - 1;

    let indentLevel = data?.indentLevel;

    // adds block with type event.target.value amd name event.target.name after index
    setBlocks(oldBlocks => {
      const newBlocks = [...oldBlocks];
      newBlocks.splice(index + 1, 0, {
        type: event.target.value,
        name: `${event.target.name}`,
        indentLevel: indentLevel,
      });
      return newBlocks;
    });

    // if event.target.value is if-else add another end-if block after it
    if (event.target.value === 'if-else') {
      // add block after index with type end-if
      setBlocks(oldBlocks => {
        const newBlocks = [...oldBlocks];
        newBlocks.splice(index + 2, 0, {
          type: 'end-if',
          name: 'end-if',
          indentLevel: indentLevel,
        });
        return newBlocks;
      });
    }
  };

  const deployApp = async (schedule) => {
    return patcher(`/app/${user.id}/${appId}/deployApi`, {
      blocks: blocks,
      schedule,
    });
  };

  const deployDiscordApp = async (command, botToken, clientId) => {
    return patcher(`/app/${user.id}/${appId}/deployDiscord`, {
      blocks: blocks,
      command,
      botToken,
      clientId,
    });
  }

  const saveApp = async (updatedAppName, updatedBlocks) => {
    if (appCreatorUserId !== user.id){
      toast({
        title: "You can't edit apps you didn't create",
        status: "error",
        position: "top",
      })
      return;
    }
    const responseData = await patcher(`/app/${user.id}/${appId}`, {
      name: updatedAppName || appName,
      blocks: updatedBlocks || blocks,
    });
  };

  const updateAppName = async (name) => {
      await setAppName(name)
      await saveApp(name)
  };

  const pruneLLMResult = res => {
    let body = JSON.parse(res.body);
    if (
      res.status >= 300 ||
      !body ||
      !body.choices ||
      body.choices.length == 0
    ) {
      return 'Error';
    }
    if (!body.choices[0].text || body.choices[0].text.length == 0) {
      return 'Empty Response. Finish Reason: ' + body.choices[0].finish_reason;
    }

    return body.choices[0].text;
  };

  const pruneSearchResult = res => {
    // console.log(res.body);
    let body = JSON.parse(res.body);
    return {
      search_results: body.organic_results.map(r => {
        return {
          link: r.link,
          title: r.title,
          snippet: r.snippet,
        };
      }),
    };
  };

  const runUISpinup = async inputText => {

    posthog.capture('run ui spinup', {
      app_id: appId,});
    const results = await poster(`/app/share/ui/${appId}`, {
      input: inputText,
    }, true);

    // return the last result
    return results;
  };

  const runApp = async () => {
    // option 2: pass blocks data as JSON from frontend and run on backend
    // console.log({ blocks, input });
    const results = await poster(`/app/${user.id}/run`, { blocks, input });
    // console.log('run app response data');
    // console.log(results);
    for (let i = 0; i < results.length; i++) {
      // if type is LLM
      if (blocks[i].type === BlockTypes.LLM) {
        setBlockRunResult(blocks[i].type, i, results[i]);
      }
      // // else if type search
      // else if (blocks[i].type === 'web-search') {
      //   setBlockRunResult(blocks[i].type, i, pruneSearchResult(results[i]));
      // } else {
      setBlockRunResult(blocks[i].type, i, results[i]);
      // }
      console.log('block run result', blocks[i].type, i, results[i]);
    }
  };

  const runRepeatBlocks = async (repeatIndex, count) => {
    // find end repeat block
    let endRepeatIndex = blocks.findIndex((block, i) => {
      if (i > repeatIndex && block.type === 'end-repeat') return true;
    });
    for (let i = 0; i < count; i++) {
      const results = await poster(`/app/${user.id}/run`, {
        blocks: blocks.slice(repeatIndex + 1, endRepeatIndex),
        input,
      });
      for (let i = repeatIndex + 1; i < endRepeatIndex; i++) {
        setBlocks(oldBlocks => {
          const newBlocks = [...oldBlocks];
          newBlocks[i].result = results[i - repeatIndex - 1];
          return newBlocks;
        });
      }
    }
  };

  // use child save app -- useEffect to save

  let value = {
    addBlockRedesigned,
    addBlock,
    deleteBlock,
    setBlockRunResult,
    setBlockData,
    setBlocks,
    blocks,
    saveApp,
    appId,
    user,
    runApp,
    input,
    lastSavedAt,
    deployApp,
    isTemplate,
    appName,
    runRepeatBlocks,
    addTrigger,
    betterDeleteBlock,
    runUISpinup,
    setLastSavedAt,
    debouncedSaveBlocks,
    expandResult,
    setExpandResult,
    updateAppName: updateAppName,
    deployDiscordApp,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  return useContext(AppContext);
}
