import {
    Box,
    Button,
    Flex,
    Heading,
    Stack,
    useColorModeValue
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useUserAuth } from "../util/auth/user-auth";
import supabase from '../util/supabase/index.ts';
import { Auth, ThemeSupa } from '@supabase/auth-ui-react'
import {useStyletron} from "baseui";
import {LabelMedium} from "baseui/typography";
import {Card} from "baseui/card";
import { useEffect } from "react";

export function LoginPage() {
    let { state } = useLocation();
    let {redirectTo } = useUserAuth();
    const [css, theme] = useStyletron()
    return (
        <div className={css({
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',

        })}>
            <Card style={{padding: '50px', maxWidth: '800px', width: '100%'}}>
            <Auth
                supabaseClient={supabase}
                redirectTo={redirectTo(state?.from || '/apps')}
                appearance={{ theme: ThemeSupa }}
                providers={['github', 'google']}
            />
            </Card>
        </div>
    );
}
