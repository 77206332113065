import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../../util/auth/user-auth';
import { useAppContext } from '../../util/app/app-provider';
import { poster } from '../../util/requests/axios';
import {
  Input,
  Button,
  Box,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  HStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import Mustache from 'mustache';
import { ResultComponent } from './result';
import debounce from 'lodash.debounce';
import { AvailableVariables } from './available-variables';
import { interpolate } from '../../util/interpolate';
import { useHTTPRequestContext } from '../../util/errors/HTTPProvider';
import { Button as BaseButton } from 'baseui/button';
import { GeneralisedBlock } from '../GeneralisedBlock';
import { BlockTypes } from '../../util/constants';

export function ScrapeBlock({ index }) {
  let { user } = useUserAuth();
  const { poster } = useHTTPRequestContext();
  let { blocks, setBlockRunResult, deleteBlock, setBlockData, input } =
    useAppContext();
  const result = blocks[index].result;
  const formik = useFormik({
    initialValues: {
      url: blocks[index]?.url,
      selector: blocks[index]?.selector,
    },
    onSubmit: async values => {
      // if url or selector is empty, don't run
      if (!values.url || !values.selector) return;
      const requestData = {
        type: blocks[index].type,
        url: interpolate(values.url, { blocks, input }),
      };
      if (values.selector)
        requestData.selector = interpolate(values.selector, { blocks, input });
      const blockRunResult = await poster(
        `/app/${user.id}/block/run`,
        requestData
      );
      // let textResult = JSON.parse(blockRunResult.body).data[0].results[0].text
      setBlockRunResult(BlockTypes.WebCrawl, index, blockRunResult);
    },
    // .data[0].results[0].text
  });


  useEffect(() => {
    const scrapeBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, scrapeBlockData);
  }, [formik.values]);

  const setBlockDataAndSave = async () => {
    const scrapeBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, scrapeBlockData);
  };

  return (
    <GeneralisedBlock
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          style={{ marginBottom: '5px' }}
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
          <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0zm2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z" />
        </svg>
      }
      isLoading={formik.isSubmitting}
      index={index}
      onRun={formik.handleSubmit}
    >
      <Stack direction={'row'} width={'100%'} padding={'10px'}>
        <Stack
          spacing={5}
          padding="20px"
          bg={'white'}
          justifyContent={'space-between'}
          direction="column"
          width={'100%'}
        >
          {/* <AvailableVariables index={index} /> */}
          <FormControl>
            <FormLabel>URL</FormLabel>
            <Input
              id="url"
              placeholder="https://gptagent.com"
              onChange={event => {
                formik.handleChange(event);
              }}
              value={formik.values.url}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Selector</FormLabel>
            <Input
              id="selector"
              placeholder="body"
              onChange={event => {
                formik.handleChange(event);
              }}
              value={formik.values.selector}
            />
          </FormControl>
        </Stack>
        <Box width={'100%'}>
          <ResultComponent result={
            result?.data?.[0]?.results?.[0]?.text
          } index={index} />
        </Box>
      </Stack>
    </GeneralisedBlock>
  );
}
