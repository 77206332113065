import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Landing } from './marketing/landing';
import { Share } from './app/share';
import { LoginPage } from './auth/login';
import { IntegrationsPage } from './settings/integrations';
import { RequireUserAuth, UserAuthProvider } from './util/auth/user-auth';
import { AppsPage } from './app/apps';
import { AppPage } from './app/app';
import { CreateAppPage } from './app/create-app';
import { AppContextProvider } from './util/app/app-provider';
import NavBar, { NavBarBase } from './nav-bar';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { Provider as StyletronProvider } from 'styletron-react';
import {
  LightTheme,
  BaseProvider,
  styled,
  DarkTheme,
  DarkThemeMove,
  useStyletron,
  LightThemeMove,
} from 'baseui';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Block } from 'baseui/block';
import { ErrorBoundary, HTTPProvider } from './util/errors/HTTPProvider';
const engine = new Styletron();



function App() {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightThemeMove}>
        <ChakraProvider theme={theme}>
          <HTTPProvider>
            <UserAuthProvider>
              <AppContent />
            </UserAuthProvider>
          </HTTPProvider>
        </ChakraProvider>
      </BaseProvider>
    </StyletronProvider>
  );
}

const AppContent = () => {
  const [,theme] = useStyletron();
  return <BrowserRouter>
    <NavBarBase />
    <Block backgroundColor={theme.colors.backgroundPrimary} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} paddingTop={[theme.sizing.scale200, theme.sizing.scale800]}>
      <Block maxWidth={"80%"} width={"100%"} >
      <Routes>
        {/*<Route path="/" element={<Landing />} />*/}
        <Route path="/login" element={<LoginPage />} />

          {['/apps', '/'].map(path =>  <Route
                  path={path}
                  element={
                      <RequireUserAuth>
                          <AppsPage />
                      </RequireUserAuth>
                  }
              />
          )}

            <Route
              path="/apps/new"
              element={
                <RequireUserAuth>
                  <CreateAppPage />
                </RequireUserAuth>
              }
            />

            <Route
              path="/apps/:appId"
              element={
                <RequireUserAuth>
                  <AppContextProvider>
                    <AppPage />
                  </AppContextProvider>
                </RequireUserAuth>
              }
            />

            <Route
            path='/share/:appId'
            element={
              <AppContextProvider>
                <Share />
              </AppContextProvider>
            }
            />
            {/* <Route path="/:userId/apps" element={
                <RequireUserAuth>
                  <AppsPage />
                </RequireUserAuth>} /> */}

            {/* <Route
              path="/integrations"
              element={
                <RequireUserAuth>
                  <IntegrationsPage />
                </RequireUserAuth>
              }
            /> */}
          </Routes>
        </Block>
      </Block>
    </BrowserRouter>
}

export default App;
