import { createContext, useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import supabase from '../supabase/index.ts';
import { useEffect, useState } from 'react';
import { SupabaseClient } from '@supabase/supabase-js';
import { toaster, ToasterContainer } from 'baseui/toast';

import axios from 'axios';
// import createAuthRefreshInterceptor from 'axios-auth-refresh';

let ErrorContext = createContext(null);

export const BASE_URL_PROD = 'https://agent-api-g4of.onrender.com';
export const BASE_URL_LOCAL = 'http://localhost:9000';

// const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV == 'development';
const isDevEnv = false;
const axiosService = axios.create({
  baseURL: isDevEnv ? BASE_URL_LOCAL : BASE_URL_PROD,
  headers: {
    'Content-Type': 'application/json',
  },
});
const nonAuthService = axios.create({
  baseURL: isDevEnv ? BASE_URL_LOCAL : BASE_URL_PROD,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosService.interceptors.request.use(async config => {
  if (config.noAuth) {
    return config;
  }
  const {
    data: {
      session: { access_token },
    },
  } = await supabase.auth.getSession();
  config.headers.Authorization = 'Bearer ' + access_token;
  // console.log('[Request]', config.baseURL + config.url, access_token);
  return config;
});

export function HTTPProvider({ children }) {
  const [error, setError] = useState(null);
  const [toastKey, setToastKey] = useState(null);

  axiosService.interceptors.response.use(
    res => {
      // @ts-ignore
      // console.debug(
      //   '[Response]',
      //   res.config.baseURL + res.config.url,
      //   res.status,
      //   res.data
      // );
      return Promise.resolve(res);
    },
    err => {
      console.debug(
        '[Response]',
        err.config?.baseURL + err.config.url,
        err.response?.status,
        err.response?.data
      );
      setError(err);
      return Promise.reject(err);
    }
  );

  useEffect(() => {
    if (error != null) {
      // console.log(error)\
      // ${error.response.data.stack}
      setToastKey(
        toaster.negative(`Sorry, we ran into an issue with the error: `, {
          autoHideDuration: 5000,
        })
      );
    }
  }, [error]);

  const fetcher = async (url, noAuth, options) => {
    options = options ? options : {};
    if (noAuth) {
      options.noAuth = true;
    }
    // options.noAuth = true

    try {
      const res = await axiosService.get(url, {
        ...options,
      });
      return res.data;
    } catch (e) {
      setError(e);
    }
  };

  const poster = async (url, data, noAuth) => {
    let options = {};
    if (noAuth) {
        // console.log("POSTER NO AUTH")
      options.noAuth = true;
    }

    const res = await axiosService.post(url, data, { ...options });
    return res.data;
  };

  const deleter = async (url, data) => {
    const res = await axiosService.delete(url, data);
    return res.data;
  };

  const patcher = async (url, data) => {
    const res = await axiosService.patch(url, data);
    return res.data;
  };

  const value = {
    fetcher,
    poster,
    deleter,
    patcher,
    didError: error != null,
    setError,
  };

  return (
    <ToasterContainer>
      <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
    </ToasterContainer>
  );
}

export function useHTTPRequestContext() {
  return useContext(ErrorContext);
}
