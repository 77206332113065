import {useAppContext} from "../util/app/app-provider";
import {useHTTPRequestContext} from "../util/errors/HTTPProvider";
import {useStyletron, withStyle} from "baseui";
import React, {useEffect, useState} from "react";
import {Block} from "baseui/block";
import {FormControl} from "baseui/form-control";
import {Link} from "baseui/link/styled-components";
import {Input} from "baseui/input";
import {Button} from "baseui/button";
import {LabelLarge} from "baseui/typography";
import {StyledBodyCell, StyledHeadCell, StyledTable} from "baseui/table-grid";
import ChevronDown from "baseui/icon/chevron-down";
import ChevronRight from "baseui/icon/chevron-right";
import {format} from "date-fns";

function buildRow(deployment) {
    return [
        deployment.id,
        new Date(deployment.createdAt),
        deployment.command,
        deployment.clientId,
    ];
}

const CenteredBodyCell = withStyle(StyledBodyCell, {
    display: 'flex',
    alignItems: 'center',
});


function Row({striped, row}) {
    const [css] = useStyletron();
    const [expanded, setExpanded] = React.useState(false);
    return (
        <div role="row" className={css({display: 'contents'})}>
            <CenteredBodyCell $striped={striped}>
                {/*<Button*/}
                {/*    size="compact"*/}
                {/*    kind="tertiary"*/}
                {/*    onClick={() => setExpanded(!expanded)}*/}
                {/*    shape="square"*/}
                {/*>*/}
                {/*    {expanded ? (*/}
                {/*        <ChevronDown size={18} />*/}
                {/*    ) : (*/}
                {/*        <ChevronRight size={18} />*/}
                {/*    )}*/}
                {/*</Button>*/}
                {row[0]}
            </CenteredBodyCell>
            <CenteredBodyCell $striped={striped}>
                {format(new Date(row[1]), 'yyyy-MM-dd h:mm a')}
            </CenteredBodyCell>
            <CenteredBodyCell $striped={striped}>
                {/*<Tag*/}
                {/*    closeable={false}*/}
                {/*    variant="outlined"*/}
                {/*    kind={statusToTagKind(row[2])}*/}
                {/*>*/}
                {/*    {row[2]}*/}
                {/*</Tag>*/}
                {row[2]}
            </CenteredBodyCell>
            {/*<CenteredBodyCell $striped={striped}>*/}
            {/*    <div*/}
            {/*        className={css({*/}
            {/*            textOverflow: 'ellipsis',*/}
            {/*            maxWidth: '200px',*/}
            {/*            overflow: 'hidden',*/}
            {/*            whiteSpace: 'nowrap',*/}
            {/*        })}*/}
            {/*    >*/}
            {/*        {row[5]}*/}
            {/*    </div>*/}
            {/*<StatefulPopover*/}
            {/*    placement={PLACEMENT.bottomLeft}*/}
            {/*    content={({close}) => (*/}
            {/*        <StatefulMenu*/}
            {/*            items={[*/}
            {/*                {label: 'Item One'},*/}
            {/*                {label: 'Item Two'},*/}
            {/*                {label: 'Item Three'},*/}
            {/*                {label: 'Item Four'},*/}
            {/*            ]}*/}
            {/*            onItemSelect={() => close()}*/}
            {/*            overrides={{*/}
            {/*                List: {style: {height: '144px', width: '138px'}},*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*>*/}
            {/*    <Button shape="square" kind="tertiary" size="compact">*/}
            {/*        <Overflow size={18} />*/}
            {/*    </Button>*/}
            {/*</StatefulPopover>*/}
            {/*</CenteredBodyCell>*/}
            {/*{expanded && <Tasks tasks={row[3]} />}*/}
        </div>
    );
}

export function DisordDeployment() {
    const {
        appId,
        user,
        deployDiscordApp,
    } = useAppContext();
    const {fetcher} = useHTTPRequestContext();
    const [css,theme] = useStyletron()
    const [deployments, setDeployments] = useState();
    const [command, setCommand] = useState('');
    const [botToken, setBotToken] = useState('');
    const [clientId, setClientId] = useState('');


    const fetchDeployments = () => {
        fetcher(`/app/${user.id}/${appId}/discordDeployments`).then((deployments) => setDeployments(deployments));
    }
    useEffect(() => {
        fetchDeployments()
    }, [])

    return (
        <Block>
            <Block>
                <Block display={'flex'} flexDirection={'column'} marginBottom={theme.sizing.scale1200}>
                    <FormControl
                        label={"Command"}
                        caption={"command that should trigger the bot. example: joke"}
                        >
                        <Input value={command} onChange={(e) => {
                            e.preventDefault()
                            const ev = e;
                            setCommand(ev.target.value)
                        }}/>
                    </FormControl>
                    <FormControl
                        label={"Bot token"}
                        caption={<Link href={"https://medium.com/@Moorad/how-can-you-create-and-deploy-your-own-discord-bot-using-javascript-node-js-e6b0fed9952d"}>instructions to get these credentials</Link>}
                    >
                        <Input value={botToken} onChange={(e) => {
                            e.preventDefault()
                            const ev = e;
                            setBotToken(ev.target.value)
                        }}/>
                    </FormControl>
                    <FormControl
                        label={"Client id"}
                    >
                        <Input value={clientId} onChange={(e) => {
                            e.preventDefault()
                            const ev = e;
                            setClientId(ev.target.value)
                        }}/>
                    </FormControl>
                    <Button onClick={() => deployDiscordApp(command, botToken, clientId).then(() => fetchDeployments())} overrides={{
                        BaseButton: {
                            style: {
                                alignSelf: 'flex-end',
                                maxWidth: theme.sizing.scale3200,
                            }
                        }
                    }}>
                        Deploy now
                    </Button>
                </Block>
                <LabelLarge marginBottom={theme.sizing.scale400}>
                    Existing deployments
                </LabelLarge>
                <StyledTable
                    $style={{
                        maxWidth: '100%'
                    }}
                    role="grid"

                    $gridTemplateColumns="max-content auto auto"
                >
                    <div role="row" className={css({display: 'contents'})}>
                        <StyledHeadCell>ID</StyledHeadCell>
                        <StyledHeadCell>Deployed at</StyledHeadCell>
                        <StyledHeadCell>Command</StyledHeadCell>
                        {/*<StyledHeadCell>Status</StyledHeadCell>*/}
                        {/*<StyledHeadCell>Last Run</StyledHeadCell>*/}
                        {/*<StyledHeadCell>Details</StyledHeadCell>*/}
                    </div>
                    {deployments?.map((deployment) => buildRow(deployment)).map((row, index) => {
                        const striped = index % 2 === 0;
                        return <Row key={index} row={row} striped={false} />;
                    })}
                </StyledTable>
            </Block>
        </Block>
    );
}
