import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../../util/auth/user-auth';
import { useAppContext } from '../../util/app/app-provider';
import { poster } from '../../util/requests/axios';
import {
  Text,
  Input,
  Button,
  Stack,
  Box,
  Heading,
  useDisclosure,
  FormLabel,
  FormControl,
  HStack,
  Textarea,
  Flex,
  GridItem,
  Grid,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Select } from 'chakra-react-select';
import { useFormik } from 'formik';
import Mustache from 'mustache';
import ResizeTextarea from 'react-textarea-autosize';
import { ResultComponent } from './result';
import { AvailableVariables } from './available-variables';
import { interpolate } from '../../util/interpolate';
import { useHTTPRequestContext } from '../../util/errors/HTTPProvider';
import { Button as BaseButton } from 'baseui/button';
import { CustomInput } from '../customInput';
import { GeneralisedBlock } from '../GeneralisedBlock';
import { SelectBlockComponent } from '../prev_variables';
import { BlockTypes } from '../../util/constants';

export function LlmBlock({ index }) {
  let { user } = useUserAuth();
  const { poster } = useHTTPRequestContext();

  let { blocks, setBlockRunResult, deleteBlock, setBlockData, input } =
    useAppContext();
  const result = blocks[index].result;
  const { isOpen: isConfigOpen, onToggle: onConfigToggle } = useDisclosure();

  const options = [
    {
      label: 'OpenAI',
      options: [
        { label: 'text-davinci-003', value: 'text-davinci-003' },
        { label: 'code-davinci-002', value: 'code-davinci-002' },
        { label: 'text-davinci-002', value: 'text-davinci-002' },
        { label: 'code-cushman-001', value: 'code-cushman-001' },
        { label: 'text-ada-001', value: 'text-ada-001' },
        { label: 'text-babbage-001', value: 'text-babbage-001' },
        { label: 'text-curie-001', value: 'text-curie-001' },
        { label: 'text-davinci-001', value: 'text-davinci-001' },
        { label: 'code-cushman-001', value: 'code-cushman-001' },
      ],
    },
    {
      label: 'Cohere',
      options: [{ label: 'coming soon', value: 'coming-soon' }],
    },
  ];

  const pruneLLMResult = res => {
    let body = JSON.parse(res.body);
    if (
      res.status >= 300 ||
      !body ||
      !body.choices ||
      body.choices.length == 0
    ) {
      return 'Error';
    }
    if (!body.choices[0].text || body.choices[0].text.length == 0) {
      return 'Empty Response. Finish Reason: ' + body.choices[0].finish_reason;
    }

    return body.choices[0].text;
  };

  const formik = useFormik({
    initialValues: {
      model: blocks[index]?.model
        ? blocks[index]?.model
        : options[0].options[0].value,
      temperature: blocks[index]?.temperature
        ? blocks[index]?.temperature
        : '0.1',
      max_tokens: blocks[index]?.max_tokens ? blocks[index]?.max_tokens : '250',
      stop: blocks[index]?.stop,
      prompt: blocks[index]?.prompt,
    },
    onSubmit: async values => {

      const blockRunResult = await poster(`/app/${user.id}/block/run`, {
        type: blocks[index].type,
        model: values.model,
        temperature: parseFloat(
          interpolate(values.temperature, { blocks, input })
        ),
        max_tokens: parseInt(interpolate(values.max_tokens, { blocks, input })),
        stop: interpolate(values.stop, { blocks, input }),
        prompt: interpolate(values.prompt, { blocks, input }),
      });
      setBlockRunResult(BlockTypes.LLM, index, pruneLLMResult(blockRunResult));
    },
  });

  useEffect(() => {
    const llmBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, llmBlockData);
  }, [formik.values, blocks[index].result]);

  const setBlockDataAndSave = async () => {
    const llmBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, llmBlockData);
  };
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [areVariablesVisible, setAreVariablesVisible] = useState(false);

  return (
    <GeneralisedBlock
      index={index}
      onRun={formik.handleSubmit}
      isLoading={formik.isSubmitting}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          style={{ marginBottom: '5px' }}
        >
          <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z" />
          <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z" />
        </svg>
      }
    >
      <Stack
        spacing={5}
        padding="20px"
        bg={'white'}
        justifyContent={'space-between'}
        direction="column"
        width={'100%'}
        // round bottom corners
        borderRadius={'md'}
        display={isCollapsed ? 'none' : 'flex'}
      >
        {/* <AvailableVariables index={index} /> */}
        <Stack
          direction={'row'}
          space={3}
          as={'button'}
          onClick={onConfigToggle}
          align="center"
        >
          <Text>Configuration</Text>
          {isConfigOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Stack>
        <Stack
          w={'full'}
          direction="row"
          bg="white"
          style={{ display: isConfigOpen ? 'flex' : 'none' }}
        >
          <Stack direction="column" w={'full'}>
            <FormControl>
              <FormLabel>Model</FormLabel>
              <Select
                id="model"
                value={{
                  label: formik.values.model,
                  value: formik.values.model,
                }}
                options={options}
                useBasicStyles
                onChange={newValue =>
                  formik.setFieldValue('model', newValue.value)
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Temperature</FormLabel>
              <Input
                id="temperature"
                onChange={event => {
                  formik.handleChange(event);
                }}
                placeholder="0.1"
                value={formik.values.temperature}
              />
            </FormControl>
          </Stack>
          <Stack direction="column" w={'full'}>
            <FormControl>
              <FormLabel>Max words</FormLabel>
              <Input
                id="max_tokens"
                onChange={event => {
                  formik.handleChange(event);
                }}
                placeholder="250"
                value={formik.values.max_tokens}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Stop word</FormLabel>
              <Input
                id="stop"
                onChange={event => {
                  formik.handleChange(event);
                }}
                placeholder=""
                value={formik.values.stop}
              />
            </FormControl>
          </Stack>
        </Stack>

        <HStack height={'100%'}>
          <Stack height={'100%'} alignSelf={'start'} width={'100%'}>
            <FormControl height={'100%'} alignSelf={'start'}>
              <FormLabel>Prompt</FormLabel>
              <Textarea
                id="prompt"
                // when clicked show variables
                onClick={() => {
                  setAreVariablesVisible(true);
                }}
                resize={'none'}
                style={{ height: '20vh', flex: 1 }}
                placeholder={
                  'You are a question answering bot. Answer the following questions to the best of your knowledge \n\nQ: Who was the first president of the united states of america? \nA:'
                }
                onChange={event => {
                  formik.handleChange(event);
                }}
                value={formik.values.prompt}
              />

              {areVariablesVisible && (
                <SelectBlockComponent
                  index={index}
                  blocks={blocks}
                  onHide={() => {
                    setAreVariablesVisible(false);
                  }}
                />
              )}
            </FormControl>
          </Stack>
          <Box
            width={'full'}
            // self end
            alignSelf={'end'}
            justifyContent={'end'}
            height={'full'}
          >
            <ResultComponent {...{ result, index }} />
          </Box>
        </HStack>
      </Stack>
    </GeneralisedBlock>
  );
}

// class HTMLParser extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { html: '' };
//   }

//   componentDidMount() {
//     // Parse the input string and set the state with the resulting HTML
//     const html = this.props.input.replace(/\n/g, '<br>');
//     this.setState({ html });
//   }

//   render() {
//     return (
//       <div dangerouslySetInnerHTML={{ __html: this.state.html }} />
//     );
//   }
// }
