import {createContext, useContext} from "react";
import {useLocation, Navigate} from "react-router-dom";
import supabase from '../supabase/index.ts';
import {useEffect, useState} from "react";
import {SupabaseClient} from "@supabase/supabase-js";
import posthog from "posthog-js";

const supabaseClient: SupabaseClient = supabase;
let AuthContext = createContext(null);

export function UserAuthProvider({ children }) {
    const [isLoading, setIsLoading] = useState(true);
    const [session, setSession] = useState(null);
    useEffect(() => {
        supabase.auth.getSession().then((session) => setSession(session.data.session)).finally(() => setIsLoading(false))
    }, [])

    supabase.auth.onAuthStateChange((event, session) => {
        // console.log(event)
        setIsLoading(true)
        setSession(session)
        setIsLoading(false)
        posthog.identify(session?.user.id)
    });

    const getRedirectURL = (from) => !process.env.NODE_ENV || process.env.NODE_ENV == 'development' ? 'https://localhost:3000':  `${window.location.origin}/${from}`;

    let signin = async (from) => {
        if(!process.env.NODE_ENV || process.env.NODE_ENV == 'development') {
            supabaseClient.auth.signInWithOAuth({
                provider: 'github',
                options: { redirectTo: `https://localhost:3000/${from}` }
            }).then((session) => setSession(session));
        } else {
            supabaseClient.auth.signInWithOAuth({
                provider: 'github',
                options: { redirectTo: `${window.location.origin}/${from}` }
            }).then((session) => setSession(session));
        }

    };

    let signout = async (callback) => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.error(error);
        }
        callback();
    }

    // let signout  = async () => {
    //     console.log("signing out")
    //     // supabaseClient.auth.signOut().then(() => setSession(null));
    // };

    let value = { signin, signout, session, isLoading, user: session?.user, redirectTo:  getRedirectURL};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useUserAuth() {
    return useContext(AuthContext);
}

export function RequireUserAuth({ children }) {
    let auth = useUserAuth();
    let location = useLocation();
    if (auth.isLoading){
        return null;
    }

    if (!auth.session) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" replace state={{ from: location.pathname }} />;
    }

    return children;
}
