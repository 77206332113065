import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';

export const SelectBlockComponent = ({ index, blocks, onHide }) => {
  const toast = useToast();

  Object.byString = function (o, s) {
    // console.log(o, s)

    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  };

  const [selectedBlockPath, setSelectedBlockPath] = useState(null);

  useEffect(() => {
    console.log(selectedBlockPath)
  }, [selectedBlockPath])

  if (index === 0) {
    return <></>;
  }

  const relevantKeys = {
    'llm': ['result'],
    'web-search': ['result', 'search_results', '_', '_'],
    'output': ['result'],
    'web-crawl': ['result', 'data', '_', 'results', '_', 'text,html'],
    'code': ['result'],
  };

    // lookup table of blocks and their most relevant starting path
  const startingPaths = {
    'web-crawl': `${index}.result.data.0.results.0`,
    'web-search': `${index}.result.search_results`,
  }

  return (
    <>
    {/* invisible div size of screen that closes on click */}
    <div>
        <Box
            position={'absolute'}
            top={'-50vh'}
            left={'-50vw'}
            width={'200vw'}
            height={'120vh'}
            bg={'transparent'}
            zIndex={'99'}
            onClick={() => onHide()}
        ></Box>

    </div>
    
    <Box
      position={'absolute'}
      width={'100%'}

      bg={'white'}
      borderRadius={'md'}
      border={'1px solid #e2e8f0'}
      zIndex={'100'}
      padding={'10px'}
      overflowY={'auto'}
      maxHeight={'300px'}
      marginBottom={'20px'}
      // shadow
      boxShadow={'0 0 10px 0 rgba(0,0,0,0.1)'}
    >
      <Flex gap={2} wrap={'wrap'} direction={'row'}>
          {/* if Object?.byString(blocks, selectedBlockPath + '.' + key) is undefined return nothing */}

          {!selectedBlockPath ? (
            <>
              <HStack width={'full'}>
                <Text
                  fontFamily={'monospace'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                >
                  Select variables from previous blocks:
                </Text>
              </HStack>
              <HStack
                width={'full'}
                height={'1px'}
                bg={'#e2e8f0'}
                marginBottom={'10px'}
              ></HStack>
            </>
          ) : (
            <></>
          )}
          {!selectedBlockPath ? (
            blocks.slice(0, index).map((block, i) => {
              return (
                <Box
                  key={index}
                  bg={'white'}
                  // width={'full'}S
                  borderRadius={'md'}
                  fontSize={'sm'}
                  border={'1px solid #e2e8f0'}
                  padding={'10px'}
                  fontWeight={'bold'}
                  onClick={() => setSelectedBlockPath('[' + i + ']')}
                  _hover={{
                    cursor: 'pointer',
                  }}
                >
                  <HStack>
                    <Text
                      color={'gray.500'}
                      fontFamily={'monospace'}
                      fontSize={'12px'}
                    >
                      {i}
                    </Text>
                    <Text>{block.name}</Text>
                  </HStack>
                </Box>
              );
            })
          ) : (
            <>
              <HStack width={'full'}>
                <Text
                  onClick={() => {

                    // remove the last part of the path
                    let newSelectedBlockPath = selectedBlockPath
                      .split('.')
                      .slice(0, -1)
                      .join('.');

                    setSelectedBlockPath(newSelectedBlockPath);
                    // console.log(selectedBlockPath);
                  }}
                  fontFamily={'monospace'}
                  fontSize={'14px'}
                  padding={'5px'}
                  // underline
                  textDecoration={'underline'}
                  style={{ cursor: 'pointer' }}

                >
                  {(
                    '< ' + selectedBlockPath.split('.').slice(-1).join('')
                  )}
                </Text>
              </HStack>
            </>
          )}

          {selectedBlockPath ? (
            Object.keys(Object.byString(blocks, selectedBlockPath))?.map(
              (key, i) => {
                // if the key is in the relevant keys for the block type
              //   if (relevantKeys[blocks[index].type] !== undefined) {
                  
              //   let intermediateIndex = selectedBlockPath.split('.').length - 1;
              //   if (!relevantKeys[blocks[index].type][intermediateIndex].includes(key)) {
              //     // if not _
              //     if (relevantKeys[blocks[index].type][intermediateIndex] !== '_'){
              //       console.log(relevantKeys[blocks[index].type][intermediateIndex])

              //     return <></>;
              //   }
              //   }
              // }
                if (!Object.byString(blocks, selectedBlockPath + '.' + key)) {
                  return <></>;
                }

                return (
                  <Box
                    key={i}
                    bg={'white'}
                    borderRadius={'md'}
                    border={'1px solid #e2e8f0'}
                    fontSize={'sm'}
                    _hover={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (
                        typeof Object.byString(
                          blocks,
                          selectedBlockPath + '.' + key
                        ) === 'object'
                      ) {
                        setSelectedBlockPath(selectedBlockPath + '.' + key);
                      } else {
                        toast({
                          title: 'Reference copied to clipboard',
                          position: 'top',
                        });
                        let newBlockPath;
                        if (isNaN(key)) {
                        newBlockPath = selectedBlockPath + '.' + key;
                        console.log(newBlockPath)
                        }else{
                          newBlockPath = selectedBlockPath + '.' + key + '.';
                          console.log(newBlockPath)
                        }
                        // if key is a number
                        
                        newBlockPath =
                          '${blocks' +
                          newBlockPath.replace(/(\.?)(\d+)\./g, '[$2]') +
                          '}';
                        navigator.clipboard.writeText(newBlockPath);

                        return;
                      }
                    }}
                  >
                    {typeof Object.byString(
                      blocks,
                      selectedBlockPath + '.' + key
                    ) === 'object' ? (
                      <Text fontWeight={'bold'} padding={'5px'}>
                        {JSON.stringify(
                          Object.byString(blocks, selectedBlockPath + '.' + key)
                        ).length > 100
                          ? JSON.stringify(
                              Object.byString(
                                blocks,
                                selectedBlockPath + '.' + key
                              )
                            ).slice(0, 100) + '...'
                          : JSON.stringify(
                              Object.byString(
                                blocks,
                                selectedBlockPath + '.' + key
                              )
                            )}
                      </Text>
                    ) : (
                      <Text fontWeight={'bold'} padding={'5px'}>
                        {Object?.byString(blocks, selectedBlockPath + '.' + key)
                          .length > 100
                          ? Object?.byString(
                              blocks,
                              selectedBlockPath + '.' + key
                            ).slice(0, 100) + '...'
                          : Object?.byString(
                              blocks,
                              selectedBlockPath + '.' + key
                            )}
                      </Text>
                    )}
                    <Text
                      bg={'gray.100'}
                      padding={'3px 5px'}
                      fontSize={'10px'}
                      fontFamily={'monospace'}
                    >
                      {/* {'blocks' + selectedBlockPath + (isNaN(key) ? ('.' + key) : ('[' + key + ']'))}
                       */}
                      {key}
                    </Text>
                  </Box>
                );
              }
            )
          ) : (
            <></>
          )}
        </Flex>
    </Box>
    </>
  );
};

{/* <Flex gap={2} wrap={'wrap'} direction={'row'}>
        {/* if Object?.byString(blocks, selectedBlockPath + '.' + key) is undefined return nothing */}

        // {!selectedBlockPath ? (
        //   <>
        //     <HStack width={'full'}>
        //       <Text
        //         fontFamily={'monospace'}
        //         fontSize={'14px'}
        //         fontWeight={'bold'}
        //       >
        //         Select output from previous blocks:
        //       </Text>
        //     </HStack>
        //     <HStack
        //       width={'full'}
        //       height={'1px'}
        //       bg={'#e2e8f0'}
        //       marginBottom={'10px'}
        //     ></HStack>
        //   </>
        // ) : (
        //   <></>
        // )}
        // {!selectedBlockPath ? (
        //   blocks.slice(0, index).map((block, i) => {
        //     return (
        //       <Box
        //         key={index}
        //         bg={'white'}
        //         // width={'full'}S
        //         borderRadius={'md'}
        //         fontSize={'sm'}
        //         border={'1px solid #e2e8f0'}
        //         padding={'10px'}
        //         fontWeight={'bold'}
        //         onClick={() => setSelectedBlockPath('[' + i + ']')}
        //         _hover={{
        //           cursor: 'pointer',
        //         }}
        //       >
        //         <HStack>
        //           <Text color={'gray.500'} fontFamily={'monospace'} fontSize={'12px'}>
        //             {i}
        //           </Text>
        //         <Text>{block.name}</Text>
        //         </HStack>
        //       </Box>
        //     );
        //   })
        // ) : (
        //   <>
        //     <HStack width={'full'}>
        //       <Text
        //         onClick={() => setSelectedBlockPath(null)}
        //         fontFamily={'monospace'}
        //         fontSize={'14px'}
        //         // underline
        //         textDecoration={'underline'}
        //         style={{ cursor: 'pointer' }}
        //       >
        //         {'< Blocks'}
        //       </Text>
        //     </HStack>
        //   </>
        // )}

        // {selectedBlockPath ? (
        //   Object.keys(Object.byString(blocks, selectedBlockPath))?.map(
        //     (key, i) => {
        //       // if undefined return nothing
        //       if (!Object.byString(blocks, selectedBlockPath + '.' + key)) {
        //         return <></>;
        //       }

        //       return (
        //         <Box
        //           key={i}
        //           bg={'white'}
        //           borderRadius={'md'}
        //           border={'1px solid #e2e8f0'}
        //           fontSize={'sm'}
        //           _hover={{
        //             cursor: 'pointer',
        //           }}
        //           onClick={() => {
        //             if (
        //               typeof Object.byString(
        //                 blocks,
        //                 selectedBlockPath + '.' + key
        //               ) === 'object'
        //             ) {
        //               setSelectedBlockPath(selectedBlockPath + '.' + key);
        //             } else {
        //               toast({
        //                 title: 'Reference copied to clipboard',
        //                 position: 'top',
        //               });
        //               navigator.clipboard.writeText(
        //                 '${blocks' + selectedBlockPath + '.' + key + '}'
        //               );
        //             }
        //           }}
        //         >
        //           {typeof Object.byString(
        //             blocks,
        //             selectedBlockPath + '.' + key
        //           ) === 'object' ? (
        //             <Text fontWeight={'bold'} padding={'5px'}>
        //               {JSON.stringify(
        //                 Object.byString(blocks, selectedBlockPath + '.' + key)
        //               ).length > 100
        //                 ? JSON.stringify(
        //                     Object.byString(
        //                       blocks,
        //                       selectedBlockPath + '.' + key
        //                     )
        //                   ).slice(0, 100) + '...'
        //                 : JSON.stringify(
        //                     Object.byString(
        //                       blocks,
        //                       selectedBlockPath + '.' + key
        //                     )
        //                   )}
        //             </Text>
        //           ) : (
        //             <Text fontWeight={'bold'} padding={'5px'}>
        //               {Object?.byString(blocks, selectedBlockPath + '.' + key)
        //                 .length > 100
        //                 ? Object?.byString(
        //                     blocks,
        //                     selectedBlockPath + '.' + key
        //                   ).slice(0, 100) + '...'
        //                 : Object?.byString(
        //                     blocks,
        //                     selectedBlockPath + '.' + key
        //                   )}
        //             </Text>
        //           )}
        //           <Text
        //             bg={'gray.100'}
        //             padding={'3px 5px'}
        //             fontSize={'10px'}
        //             fontFamily={'monospace'}
        //           >
        //             {'blocks' + selectedBlockPath + '.' + key}
        //           </Text>
        //         </Box>
        //       );
        //     }
        //   )
        // ) : (
        //   <></>
        // )}
      // </Flex> */}