import React, {useCallback} from 'react';
import {
  Box,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Button,
  HStack,
} from '@chakra-ui/react';
//import useToast
import { useToast } from '@chakra-ui/react';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';

import { useEffect, useState } from 'react';
import { useHTTPRequestContext } from '../util/errors/HTTPProvider';
import { useAppContext } from '../util/app/app-provider';
import {useHotkeys} from "react-hotkeys-hook";

export function Share() {
  const toast = useToast();
  const [output, setOutput] = useState('');
  const [input, setInput] = useState('');
  const [multiInput, setMultiInput] = useState([]);
  const [outputProcessing, setOutputProcessing] = useState(false);
  const { fetcher } = useHTTPRequestContext();
  const { appCreatorUserId, appId, runUISpinup } = useAppContext();
  const allowlist = ['be8ec573-2a4c-4d6a-a024-0c7e0f20b360']
  const [data, setData] = useState({
    data: [],
    loading: true,
  });
  const [inputs, setInputs] = useState([]);
  const [num_inputs, setNumInputs] = useState(1);

  useEffect(() => {
    fetcher(`share/${appId}`, true).then(res => {
      setData({
        data: res,
        loading: false,
      });

    setNumInputs(JSON.parse(res.input_details).length);
    // if both title and placeholder of input are empty, then set num_inputs to 0
    if (JSON.parse(res.input_details).length === 1 && JSON.parse(res.input_details)[0].title === "" && JSON.parse(res.input_details)[0].placeholder === "") {
      setNumInputs(0);
    }
      console.log(res);
      setInputs(JSON.parse(res.input_details))
    });

    console.log(num_inputs)
  }, [  ]);

  console.log(data.data.userId);
  const isMac = () => {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  };

  const getOutput = async () => {
    // if ((input.length < 1) && data.data?.num_inputs === 1) return;
    setOutputProcessing(true);
    const result = await runUISpinup(multiInput);
    let output = result[result.length - 1];
    console.log(output);
    // output type handling
    if (typeof output === 'object') {
      output = JSON.stringify(output);
    }
    if (typeof output === 'number') {
      output = output.toString();
    }
    if (typeof output === 'string') {
      output = output.trim();
    }
    if (output === undefined) {
      output = 'undefined';
    }

    setOutput(output);
    setOutputProcessing(false);
  };

  const onSubmit = useCallback((ev) => {
    if (ev) {
      ev.preventDefault()
    }

    getOutput()
  }, [getOutput])

  useHotkeys('meta+enter', () => onSubmit(), {
    enableOnFormTags: true,
  })

  const whitelableled = data.loading || allowlist.includes(data.data.userId);
  return (
    <Box
      textAlign="start"
      fontSize="xl"
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      fontFamily={'Inter'}
      marginTop={10}
    >
      <Box
        // minH={'100vh'}
        width={['100%', '100%', '100%', '70%']}
        // padding={10}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Stack width={'full'} spacing={0}>
          <Text
            fontWeight={'bold'}
            fontSize={['3xl', '3xl', '3xl', '5xl']}
            lineHeight={['1.1', '1.2', '1.3', '1.4']}
          >
            {data.loading ? 'Loading...' : data.data?.name}
          </Text>
          <HStack width={'full'} justifyContent={'space-between'}>
            <Text fontSize={['md', 'md', 'md', 'lg']}>
              {data.loading ? 'Loading...' : data.data?.description}
            </Text>
            {/* dont render share button for medcoach app*/}
            {!whitelableled && (

              <Button
              padding={'0px'}
              bg={'#DADADA'}
              color={'black'}
              transition={'all 0.2s ease-in-out'}
              _active={{
                transform: 'scale(0.95)',
              }}
              _hover={{
                bg: '#C7C7C7',
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://app.gptagent.com/share/${appId}`
                );
                toast({
                  title: 'Link copied to clipboard',
                  status: 'success',
                  duration: 2000,
                  position: 'top',
                });
              }}
            >

              <HStack margin={'15px'}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-link"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                </svg>
                <Text
                  // dissapear on mobile
                  display={['none', 'none', 'block', 'block']}
                  fontSize={['sm', 'sm', 'sm', 'md']}
                >
                  {' '}
                  Share{' '}
                </Text>
              </HStack>
            </Button>
            )}

          </HStack>
        </Stack>

        <Box width={'full'} height={'1px'} bg={'#C7C7C7'} mt={'10px'} />
        <Stack width={'full'} marginTop={'5%'} as="form" onSubmit={onSubmit}>
          <FormControl >
            {(
              <Stack
              >
                {
                inputs.map((input, i) => {
                  // if num_inputs is 0, then don't render any inputs
                  if (num_inputs === 0) return null;


                  return (
                    <>
                      <FormLabel
                      margin={'0px'}
                        style={{
                          fontWeight: 'bold',
                          fontSize: '2xl',
                        }}
                      >
                        {data.loading ? 'Loading...' :
                        input.title || ''
                        }
                      </FormLabel>
                      <Input
                        placeholder={
                          data.loading ? 'Loading...' :
                          input.placeholder || ''
                        }
                        value={multiInput[i]}
                        onChange={e => {
                          e.preventDefault();
                          const ev = e;
                          setMultiInput((multiInput) => {
                            let newMultiInput = [...multiInput];
                            newMultiInput[i] = ev.target.value;
                            return newMultiInput;
                          });
                        }}
                      />
                    </>
                  );
                })}
              </Stack>
            )}
          </FormControl>
          <Button
            disabled={outputProcessing}
            width={'full'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            bg={'black'}
            color={'white'}
            borderRadius={'10px'}
            _active={{
              transform: 'scale(0.98)',
            }}
            transition={'all 0.1s ease-in-out'}
            _hover={{
              bg: 'gray.800',
            }}
            type="submit"
          >
            {outputProcessing ? <Counter></Counter> : <Text>GO </Text>}
          </Button>
          <Text
            // self center
            textAlign={'center'}
            fontFamily={'monospace'}
            fontSize={'xs'}
            marginBottom={'5px'}
          >
            {(isMac() ? '⌘' : 'Ctrl') + ' + Enter'}
          </Text>
        </Stack>
        <Box
          display={output.length > 0 ? 'flex' : 'none'}
          cursor={'pointer'}
          _hover={{
            transform: 'scale(1.01)',
          }}
          title={'Copy to clipboard'}
          onClick={() => {
            navigator.clipboard.writeText(output);
            toast({
              title: 'Copied to clipboard',
              status: 'success',
              duration: 2000,
            });
          }}
          width={'full'}
          minHeight={'20vh'}
          maxHeight={'50vh'}
          mt={'30px'}
          borderRadius={'10px'}
          border={'2px'}
          borderColor={'black'}
          transition={'opacity 0.2s ease-in-out, transform 0.1s ease-in-out'}
          justifyContent={'center'}
          padding={'20px 40px'}
          textAlign={output.length > 200 ? 'start' : 'center'}
          overflow={'auto'}
        >
          <Text
            width={'full'}
            style={{
              fontWeight: output.length > 200 ? 'normal' : 'bold',
              // fontsize depending on the length of output
              fontSize:
                output.length > 200
                  ? ['xs', 'xs', 'xs', 'sm']
                  : ['lg', 'lg', 'xl', '2xl'],
            }}
          >
            {output.split('\n').map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </Text>
        </Box>
        {/* box in the center saying built on Agent */}
        {!whitelableled && (
        <Stack
          marginTop={output.length > 0 ? '5%' : '10%'}
          fontFamily={'Inter'}
          fontSize={'md'}
          display={'flex'}
          alignItems={'center'}
          marginBottom={'20px'}
        >
          <HStack>
            <Text>Built on</Text><Text fontWeight={'bold'}>Agent</Text>
          </HStack>
          <Box
            cursor={'pointer'}
            transition={'all 0.1s ease-in-out'}
            _hover={{
              transform: 'scale(1.03)',
            }}
            onClick={() => {
              window.open('https://www.gptagent.com/home-base', '_blank');
            }}
            display={'flex'}
          >
            <Text
              // responsive font size
              fontSize={['xs', 'xs', 'sm', 'md']}
              color={'white'}
              fontWeight={'bold'}
              bg={'#067EFE'}
              padding={'10px 20px'}
              borderRadius={'50px'}
            >
              Build with Agent
            </Text>
          </Box>
        </Stack>)}
      </Box>
    </Box>
  );
}

function Counter() {
  const [seconds, setSeconds] = useState(0);
  const [milliseconds, setMilliseconds] = useState(0);

  useEffect(() => {
    let interval = null;
    let startTime = performance.now();

    interval = setInterval(() => {
      const currentTime = performance.now();
      const deltaTime = currentTime - startTime;
      startTime = currentTime;
      setSeconds(seconds => seconds + Math.floor(deltaTime / 1000));
      setMilliseconds(milliseconds => milliseconds + (deltaTime % 1000));
    }, 10);
    return () => clearInterval(interval);
  }, []);

  return (
    <h1>
      processing... {Math.floor(milliseconds / 100) / 10}
      {Math.floor(milliseconds / 100) % 10 === 0 ? '.0' : ''} s
    </h1>
  );
}
