import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { useUserAuth } from './util/auth/user-auth';
import { AppNavBar } from 'baseui/app-nav-bar';
import { Delete } from 'baseui/icon';
import { useLocation, useNavigate } from 'react-router-dom';

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Image src={'mona-lisa-airpods.png'} width={'2%'} mx={'20px'} />
          Agent
          <Flex display={{ base: 'none', md: 'flex' }} ml={70}>
            <DesktopNav />
          </Flex>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  let { user, session, signout } = useUserAuth();

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map(navItem => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontWeight={500}
                target={navItem.target ?? ''}
                rel={navItem.ref ?? ''}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map(child => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
      {!session ? (
        <Box key={'Login'}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={'/login'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {'Login'}
              </Link>
            </PopoverTrigger>
          </Popover>
        </Box>
      ) : (
        <Box key={'Logout'}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                onClick={() => signout(() => {})}
                p={2}
                href={'#'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {'Logout'}
              </Link>
            </PopoverTrigger>
          </Popover>
        </Box>
      )}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}
    >
      {NAV_ITEMS.map(navItem => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

export const NavBarBase = () => {
  let { user } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  // if location.pathname contains /share then dont show navbar
  if (location.pathname.toLowerCase().includes('/share')) {
    return null;
  }
  return (
    <AppNavBar
      title={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontFamily: 'Inter'
        
        }}>
          Agent
          <div
          style={{
            marginLeft: '6px',
            fontSize: '10px',
            color: 'gray',
            // self flex end
            marginTop: '9px',
          }}
          >BETA</div>
        </div>
      }
      // mainItems={mainItems}
      userItems={[
        {
          label: 'Logout',
          icon: Delete,
        },
      ]}
      mainItems={NAV_ITEMS.map(item => ({
        active: location.pathname == item.href,
        label: item.label,
        href: item.href,
      }))}
      onMainItemSelect={item => {
        // if begins with https:// or http:// then open in new tab
        if (item.href?.startsWith('http')) {
          window
            .open(item.href, '_blank')
            ?.focus();
        } else {
          navigate(item.href);
        }
        
      }}
      onUserItemSelect={item => navigate(item.href)}
      username={'Agent User'}
      usernameSubtitle={user?.email}
      // userImgUrl=""
    />
  );
};
const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4}
    fontFamily="Inter"
    onClick={children && onToggle} 
    
    >
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}
        >
          {children &&
            children.map(child => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  // {
  //   label: 'Home',
  //   href: '/',
  // },
  {
    label: 'Apps',
    href: '/apps',
  },
  // {
  //   label: 'Integrations',
  //   href: '/integrations',
  // },
  {
    label: 'Docs',
    href: 'https://gptagent.notion.site/Agent-Docs-f72c91ab92db46efb4949af4893da778',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  {
    label: 'Feedback',
    href: 'https://l3cjthqpfva.typeform.com/to/ZWGuqxjO',
    target: '_blank',
    rel: 'noopener noreferrer',
  }
  // {
  //     label: 'Login',
  //     href: '/login',
  // },
];
