import React from 'react';
import { FormLabel, FormControl, Box, Image } from '@chakra-ui/react';
import { useAppContext } from '../../util/app/app-provider';
import { Button as BaseButton } from 'baseui/button';
import { HStack } from '@chakra-ui/react';
import { Heading } from '@chakra-ui/react';
import { useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Text } from '@chakra-ui/react';

export function TriggerBlock({ index, icon, appID}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  let { blocks, deleteBlock } = useAppContext();
  const [isConfigOpen, setIsConfigOpen] = useState(true);

  return (
    <Box
      // add index before the block type=
      w={'full'}
      // border
      borderColor={'gray.200'}
      borderWidth={'1px'}
      rounded={'md'}
      alignItems="start"
      bg={'white'}
      transition={'all 0.19s ease-in-out'}
      _hover={{
        transform: isCollapsed ? 'scale(1.02)' : 'scale(1)',
        shadow: 'md',
      }}
    >
      <HStack
        justify={'space-between'}
        bg={'black'}
        w={'full'}
        borderRadius={'md'}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        <HStack
          padding="20px"
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {icon}
          <Heading
            textAlign="left"
            color={'white'}
            fontSize={'l'}
            fontFamily={'body'}
            // center vertically
          >
            {blocks[index].name}
          </Heading>
        </HStack>
        <HStack justify={'flex-end'} pr={'10px'} cornerRadius={'md'}>
          
          <BaseButton
            size="compact"
            kind={'tertiary'}
            style={{
              color: 'white',
              backgroundColor: 'transparent',
              border: 'none',
            }}

            onClick={() => {
              deleteBlock(index);
            }}
          >
            Delete
          </BaseButton>
        </HStack>
      </HStack>
      <Box display={isCollapsed ? 'none' : 'block'} h={'20vh'}>
        <Stack width={'full'} height={'full'} padding={'10px'}>
          <HStack
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setIsConfigOpen(!isConfigOpen);
            }}
          >
            <Text>Configuration</Text>
            {isConfigOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </HStack>
          <HStack display={isConfigOpen ? 'flex' : 'none'}>
            <Stack
              width={'50%'}
              height={'full'}
              border={'1px solid #E9EEF4'}
              padding={'10px'}
              borderRadius={'md'}
            >
              <HStack width={'full'} justify={'space-between'}>
                <div>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'start'}
                    cursor={'pointer'}
                  >
                    <Image
                      src={'../DiscordLogo.png'}
                      width={'50px'}
                      style={{
                        borderRadius: '18px',
                        border: '1px solid #E9EEF4',
                        marginRight: '10px',
                      }}
                    />
                    <Text fontWeight={'bold'}>Discord</Text>
                  </Box>
                </div>

                <Text padding={'8px 10px'} bg={'#E9EEF4'}
                cursor={'pointer'}
                fontWeight={'bold'}
                transition={'all 0.19s ease-in-out'}
                _hover={{
                  bg: '#5864f2',
                  color: 'white',
                }}
                onClick={() => {
                  const url = window.location.href;
                  const appID = url.split('/')[4];
                  window.open(`https://discord.com/api/oauth2/authorize?client_id=1065610367054651422&permissions=534723950656&redirect_uri=https%3A%2F%2Fb2b5-103-94-59-110.in.ngrok.io%2Fdiscord&response_type=code&scope=bot%20applications.commands%20identify%20guilds%20email&state=${appID}`, "_blank");
                }}

                borderRadius={'md'}
                >
                  Connect
                </Text>
              </HStack>

              <HStack width={'full'} justify={'space-between'}>
                <div>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'start'}
                    cursor={'pointer'}
                  >
                    <Image
                      src={'../SlackLogo.png'}
                      width={'50px'}
                      style={{
                        borderRadius: '18px',
                        border: '1px solid #E9EEF4',
                        marginRight: '10px',
                      }}
                    />
                    <Text fontWeight={'bold'}>Slack</Text>
                  </Box>
                </div>

                <Text padding={'8px 10px'} bg={'#E9EEF4'}
                cursor={'pointer'}
                fontWeight={'bold'}
                transition={'all 0.19s ease-in-out'}
                _hover={{
                  bg: '#df205c',
                  color: 'white',
                }}

                borderRadius={'md'}>
                  Connect
                </Text>
              </HStack>
            </Stack>
            <Stack
              width={'50%'}
              height={'full'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Text>connect an app to continue</Text>
            </Stack>
          </HStack>
        </Stack>
      </Box>
    </Box>
  );
}
