import React, { useEffect, useRef, useState } from 'react';
import {
  Flex,
  Button,
  Stack,
  Container,
  Menu,
  MenuButton,
  Box,
  MenuList,
  MenuItem,
  Text,
  Heading,
  useDisclosure,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Router, useParams } from 'react-router-dom';
import { useAppContext } from '../util/app/app-provider';
import { CodeBlock } from './blocks/code';
import { HttpBlock } from './blocks/http';
import { LlmBlock } from './blocks/llm';
import { ElseBlock, IfElseBlock } from './blocks/if-block';
import { ScrapeBlock } from './blocks/scrape';
import { SearchBlock } from './blocks/search';
import { InputBlock } from './blocks/input';
import { Input } from 'baseui/input';
import { TriggerBlock } from './blocks/trigger';
import { DiscordBlock } from './blocks/discord';
import { SqlBlock } from './blocks/sql';
import debounce from 'lodash.debounce';
import { AppModalBlock } from './blocks/app-modal';
import { EndIf } from './blocks/end-if';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { Button as BaseButton } from 'baseui/button';
import { LoadingComponent } from '../util/loadingComponent';
import {
  DisplayMedium,
  HeadingMedium,
  HeadingSmall,
  LabelLarge,
  LabelMedium,
  ParagraphSmall,
} from 'baseui/typography';
import { useUserAuth } from '../util/auth/user-auth';
import { useNavigate } from 'react-router-dom';
import { Card, StyledBody } from 'baseui/card';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { PLACEMENT } from 'baseui/badge';
import { StatefulPopover } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import { ChevronDown } from 'baseui/icon';
import { useStyletron } from 'baseui';
import { useHTTPRequestContext } from '../util/errors/HTTPProvider';
import { Deployments } from './deployment';
import { DeploymentTwo } from './deployment-two';
import { Fade, ScaleFade, Slide, SlideFade } from '@chakra-ui/react';
import { ForLoopBlock } from './blocks/for-block';
import { EndRepeat } from './blocks/end-for';
import { SlackBlock } from './blocks/slack';
import { OutputBlock } from './blocks/output';
import { Spinner } from '@chakra-ui/react';
import { UIDeploy } from './ui-deploy';
import { BlockTypes } from '../util/constants';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import {AppSettings} from "./app-settings";
import {Accordion, Panel} from "baseui/accordion";
import {DeploymentMaster} from "./deployment_master";

const ITEMS = {
  Scripting: [
    {
      label: 'JavaScript Function',
      value: BlockTypes.Code,
      name: 'Run Function',
      description: 'Run a JavaScript function',
    },
    // {
    //   label: 'Input',
    //   value: BlockTypes.Input,
    //   name: 'Input',
    //   description: 'Accept input as text',
    // },
    // {
    //   label: 'Conditional',
    //   value: BlockTypes.IfElse,
    //   name: 'Conditional',
    //   description:
    //     'Execute blocks only if a specified JS eval condition is true',
    // },
    {
      label: 'HTTP Request',
      value: BlockTypes.HTTP,
      name: 'HTTP Request',
      description: 'Send an HTTP request and handle the response',
    },
    // {
    //   label: 'Repeat',
    //   value: BlockTypes.Repeat,
    //   name: 'Repeat',
    //   description: 'Execute blocks of code multiple times',
    // },
    {
      label: 'Web Scrape',
      value: BlockTypes.WebCrawl,
      name: 'Web Scrape',
      description: 'Extract data from a website',
    },
    // {
    //   label: 'Output',
    //   value: BlockTypes.Output,
    //   name: 'Output',
    //   description: 'Displays output',
    // }
  ],
  Apps: [
    {
      label: 'LLM',
      value: BlockTypes.LLM,
      name: 'LLM',
      description: 'Use a language model to generate text',
    },
    {
      label: 'Discord',
      value: BlockTypes.Discord,
      name: 'Discord',
      description:
        'Connect to a Discord webhook to send messages to a Discord channel',
    },
    {
      label: 'Slack',
      value: BlockTypes.Slack,
      name: 'Slack',
      description:
        'Connect to a Slack webhook to send messages to a Slack channel',
    },
    {
      label: 'Web Search',
      value: BlockTypes.WebSearch,
      name: 'Web Search',
      description: 'Search the web for a specified query',
    },
    // TODO: uncomment when ready to add postgres integration
    // {
    //   label: 'SQL',
    //   value: BlockTypes.SQL,
    //   name: 'SQL',
    //   description: 'Execute SQL (must set up DB integration)',
    // },
  ],
};

export function AppPage() {
  const {
    blocks,
    addBlock,
    addBlockRedesigned,
    saveApp,
    runApp,
    createApp,
    lastSavedAt,
    setLastSavedAt,
    isTemplate,
    appName,
    setBlocks,
    setBlockData,
    addTrigger,
    debouncedSaveBlocks,
    expandResult,
    setExpandResult,
  } = useAppContext();

  let { user } = useUserAuth();
  let navigate = useNavigate();
  const [triggerOpen, setTriggerOpen] = useState(false);
  const [isAppRunning, setAppRunning] = useState(false);
  const { fetcher, poster, deleter, patcher, didError } =
    useHTTPRequestContext();
  const [activeKey, setActiveKey] = useState(0);
  const [, theme] = useStyletron();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { isSelectOpen, onToggle } = useDisclosure();
  const acceptedUserIDs = [
    '6d9e19fc-2b16-4f0b-ae19-14d09c6c85e7',
    '6574e162-4cb0-42c5-bba0-0232d9ee589f',
    'ffe9bbb7-219b-473a-b330-aa8df98a2b5d',
    '295df342-218b-4f18-9ef5-437ea90b0d24',
  ]; // agent team

  const isDeploymentsDisabled = () => {
    if (acceptedUserIDs.includes(user.id)) {
      // console.log('true')
      return true;
    }
  };

  useEffect(() => {
    if (didError) {
      setAppRunning(false);
    }
  }, [didError]);
  return (
    <>
      <Modal
      size={'xl'}
      isOpen={expandResult !== ''} isCentered onClose={() => setExpandResult('')}>
        <ModalOverlay />
        <ModalContent
          style={{
            width: '50vw',
            height: '70%',
            overflow: 'auto',
          }}
        >
          <ModalHeader>Expanded Result</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{expandResult}</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => setExpandResult('')}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tabs
        activeKey={activeKey}
        onChange={({ activeKey }) => setActiveKey(activeKey)}
        overrides={{ Root: { style: { width: '100%' } } }}
      >
        <Tab key={0} title={'Definition'}>
          <Flex
            minWidth="max-content"
            alignItems="center"
            gap={10}
            direction={'column'}
          >
            <Stack direction={'row'} justify={'space-between'} w={'100%'}>
              <Stack
                spacing={3}
                align={'center'}
                direction={'row'}
                justify={'flex-start'}
              >
                <BaseButton
                  disabled={isAppRunning}
                  overrides={{
                    // add a transition
                    BaseButton: {
                      style: ({ $theme }) => ({
                        transition: $theme.animation.timing100,
                      }),
                    },
                    // add a transition to the icon
                  }}
                  onClick={async () => {
                    setAppRunning(true);
                    // run app then set app running to false when response is received
                    await runApp().then(() => setAppRunning(false));
                  }}
                >
                  {isAppRunning ? (
                    <Flex gap={3}>
                      <Spinner
                        alignSelf={'center'}
                        thickness="4px"
                        speed="0.65s"
                      ></Spinner>
                      <Counter />
                    </Flex>
                  ) : (
                    <Text>Run All</Text>
                  )}
                </BaseButton>
                {!isTemplate && (
                  <BaseButton kind="secondary" onClick={() => saveApp(blocks)}>
                    Save
                  </BaseButton>
                )}
                {isTemplate && (
                  <BaseButton
                    kind="secondary"
                    onClick={async () => {
                      await poster(`/app/${user.id}/new`, {
                        name: `${appName} (clone)`,
                        blocks: blocks,
                      }).then(responseData =>
                        navigate(`/apps/${responseData.id}`)
                      );
                      // reload page
                      window.location.reload();
                    }}
                  >
                    Clone
                  </BaseButton>
                )}
              </Stack>

              {lastSavedAt && (
                <Stack>
                  <Heading size={'sm'}>Last saved</Heading>
                  <Text>{lastSavedAt.toLocaleString()}</Text>
                </Stack>
              )}
            </Stack>

            <Stack spacing={3} align={'center'} direction={'column'} w={'100%'}>
              {/* if blocks is length 0 then show loading indicator */}
              {blocks.length === 0 && (
                <LoadingComponent
                  title="No Blocks?"
                  description="You haven't added any blocks yet. Click the New block button above to add your first block"
                />
              )}
              {/* {(blocks[0]?.type !== 'trigger') && (blocks.length !== 0) && <Box
              onClick={() => {
                addTrigger();
                setTriggerOpen(false);
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                transition={'all 0.1s ease-in-out'}
                _hover={{
                  cursor: 'pointer',
                  backgroundColor: 'black',
                  color: 'white',
                }}
                style={{
                  borderRadius: '30px',
                  padding: '3px 20px 3px 20px',
                  border: '1px solid black',
                  fontWeight: 'bold',
                }}
              >
                Add Trigger
              </Box>
            </Box>} */}

              {blocks.map((block, index) => {
                let newBlock;
                const commonProps = {
                  index,
                };
                switch (block.type) {
                  case BlockTypes.Trigger:
                    newBlock = <TriggerBlock {...commonProps} />;
                    break;
                  case BlockTypes.Code:
                    newBlock = <CodeBlock {...commonProps} />;
                    break;
                  case BlockTypes.Input:
                    newBlock = <InputBlock {...commonProps} />;
                    break;
                  case BlockTypes.IfElse:
                    newBlock = <IfElseBlock {...commonProps} />;
                    break;
                  case BlockTypes.EndIf:
                    newBlock = <EndIf {...commonProps}></EndIf>;
                    break;
                  case BlockTypes.HTTP:
                    newBlock = <HttpBlock {...commonProps} />;
                    break;
                  case BlockTypes.LLM:
                    newBlock = <LlmBlock {...commonProps} />;
                    break;
                  case BlockTypes.WebCrawl:
                    newBlock = <ScrapeBlock {...commonProps} />;
                    break;
                  case BlockTypes.WebSearch:
                    newBlock = <SearchBlock {...commonProps} />;
                    break;
                  case BlockTypes.Discord:
                    newBlock = <DiscordBlock {...commonProps} />;
                    break;
                  case BlockTypes.Slack:
                    newBlock = <SlackBlock {...commonProps} />;
                    break;
                  case BlockTypes.AppModal:
                    newBlock = <AppModalBlock {...commonProps} />;
                    break;
                  case BlockTypes.Repeat:
                    newBlock = <ForLoopBlock {...commonProps} />;
                    break;
                  case BlockTypes.EndRepeat:
                    newBlock = <EndRepeat {...commonProps} />;
                    break;
                  case BlockTypes.SQL:
                    newBlock = <SqlBlock {...commonProps} />;
                    break;
                  case BlockTypes.Output:
                    newBlock = (
                      <OutputBlock {...commonProps} output={'Hello World'} />
                    );
                    break;
                  default:
                    break;
                }
                return (
                  <Box
                    w={'full'}
                    maxW={'50vw'}
                    pl={block.indentLevel ? 10 : 0}
                    key={index}
                  >
                    <div>{newBlock}</div>
                    {!isTemplate ? (
                      <Flex justifyContent="center">
                        <StatefulPopover
                          focusLock
                          placement={PLACEMENT.bottomLeft}
                          content={({ close }) => (
                            <Box
                              padding={'4px'}
                              bg={'white'}
                              width={'full'}
                              borderRadius={'5px'}
                            >
                              {Object.keys(ITEMS).map(key => (
                                <Flex
                                  padding={'4px'}
                                  spacing={'4px'}
                                  direction={'column'}
                                >
                                  <Text fontWeight={'bold'}>{key}</Text>
                                  {ITEMS[key].map(item => (
                                    <Box
                                      _hover={{
                                        bg: '#f6f6f6',
                                      }}
                                      marginLeft={'6px'}
                                      cursor={'pointer'}
                                      bg={'white'}
                                      padding={'4px'}
                                      borderRadius={'4px'}
                                      key={item.value}
                                      title={item.description}
                                      onClick={() => {
                                        addBlockRedesigned(item, {
                                          index: index,
                                          indentLevel:
                                            block.type === 'if-else' ||
                                            ((block.type === 'repeat') |
                                              (block.indentLevel === 1) &&
                                              1),
                                        });
                                        close();
                                      }}
                                    >
                                      {item.name}
                                    </Box>
                                  ))}
                                </Flex>
                              ))}
                            </Box>
                            //    <StatefulMenu
                            //   items={ITEMS}
                            //   onItemSelect={({ item }) => {
                            //     addBlockRedesigned(item);
                            //     close();
                            //   }}
                            //   overrides={{
                            //     List: { style: { width: theme.sizing.scale4800 } },
                            //     ListItem: {
                            //       props: { title:  },
                            //     },
                            //   }}
                            // />
                          )}
                        >
                          {block.type !== 'output' && (
                            <BaseButton
                              overrides={{
                                Root: {
                                  style: {
                                    fontFamily: 'Inter',
                                    marginTop: theme.sizing.scale800,
                                    position: 'relative',
                                    ':before': {
                                      content: '""',
                                      position: 'absolute',
                                      height: '300%',
                                      zIndex: -1,
                                      top: '-25px',
                                      bottom: 0,
                                      left: '50%',
                                      borderLeft: '1px solid #666',
                                      display:
                                        index === blocks.length - 1
                                          ? 'none'
                                          : 'block',
                                    },
                                  },
                                },
                              }}
                              size={'default'}
                              shape={blocks.length <= 2 ? 'pill' : 'circle'}
                            >
                              {/* if length of blocks is more than 2 show add blocks */}
                              {blocks.length <= 2 ? 'Add Block' : '+'}
                            </BaseButton>
                          )}
                        </StatefulPopover>
                        {/*<Menu>*/}
                        {/*    <MenuButton as={Button} colorScheme='messenger'*/}
                        {/*        marginTop={'10px'}*/}
                        {/*        _before={{*/}
                        {/*            content: '""',*/}
                        {/*            position: 'absolute',*/}
                        {/*            height: '200%',*/}
                        {/*            zIndex: -1,*/}
                        {/*            top: '-25px',*/}
                        {/*            bottom: 0,*/}
                        {/*            left: '50%',*/}
                        {/*            borderLeft: '1px solid #ccc',*/}
                        {/*            display: index === blocks.length - 1 ? 'none' : 'block'*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        +*/}
                        {/*    </MenuButton>*/}
                        {/*    <MenuList>*/}
                        {/*        <MenuItem name="RUN_FUNCTION" value='code' onClick={onClick}>Run JavaScript</MenuItem>*/}
                        {/*        <MenuItem name="INPUT_BLOCK" value='input' onClick={onClick}>Add Input</MenuItem>*/}
                        {/*        <MenuItem name="IF_BLOCK" value='if-else' onClick={onClick}>If-Else Block</MenuItem>*/}
                        {/*        <MenuItem name="HTTP_REQUEST" value='http' onClick={onClick}>HTTP Request</MenuItem>*/}
                        {/*        <MenuItem name="LLM_COMPLETE" value='llm' onClick={onClick}>LLM Complete</MenuItem>*/}
                        {/*        <MenuItem name="DISCORD" value='discord' onClick={onClick}>Discord</MenuItem>*/}
                        {/*        <MenuItem name="WEB_SCRAPE" value='web-crawl' onClick={onClick}>Web Scrape</MenuItem>*/}
                        {/*        <MenuItem name="WEB_SEARCH" value='web-search' onClick={onClick}>Web Search</MenuItem>*/}
                        {/*        <MenuItem name="APP_MODAL" value='app-modal' onClick={onClick}>App</MenuItem>*/}
                        {/*    </MenuList>*/}
                        {/*</Menu>*/}
                      </Flex>
                    ) : null}
                  </Box>
                );
              })}
            </Stack>
            {/* create a footer */}
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              w={'full'}
              mt={10}
            >
              <Text fontSize={'sm'} color={'gray.500'}>
                {blocks.length} Blocks
              </Text>
            </Flex>
          </Flex>
        </Tab>
        <Tab key={1} title={'Deployments'}>
        <DeploymentMaster />
          {/* <DeploymentTwo />
           */}
          {/*<UIDeploy></UIDeploy>*/}
        </Tab>
        <Tab key={2} title={'Settings'}>
          <AppSettings />
        </Tab>
      </Tabs>
    </>
  );
}

function Counter() {
  const [seconds, setSeconds] = useState(0);
  const [milliseconds, setMilliseconds] = useState(0);

  useEffect(() => {
    let interval = null;
    let startTime = performance.now();
    interval = setInterval(() => {
      const currentTime = performance.now();
      const deltaTime = currentTime - startTime;
      startTime = currentTime;
      setSeconds(seconds => seconds + Math.floor(deltaTime / 1000));
      setMilliseconds(milliseconds => milliseconds + (deltaTime % 1000));
    }, 10);
    return () => clearInterval(interval);
  }, []);

  return (
    <h1>
      {Math.floor(milliseconds / 100) / 10}
      {Math.floor(milliseconds / 100) % 10 === 0 ? '.0' : ''} s
    </h1>
  );
}
