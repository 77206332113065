import React, { useEffect, useState, useMemo } from 'react';
import { useUserAuth } from '../../util/auth/user-auth';
import { useAppContext } from '../../util/app/app-provider';
import { Box, Stack } from '@chakra-ui/react';
import { useHTTPRequestContext } from '../../util/errors/HTTPProvider';
import { useToast } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { GeneralisedBlock } from '../GeneralisedBlock';


export function OutputBlock({ index }) {
  let { user } = useUserAuth();
  const { poster } = useHTTPRequestContext();
  let { blocks, setBlockRunResult, deleteBlock, setBlockData, input } =
    useAppContext();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toast = useToast();

  const [areVariablesVisible, setAreVariablesVisible] = useState(false);
    let output = blocks[index-1]?.result;
  if (blocks[index-1]?.type === 'input'){
    output = blocks[index-1].inputs;
  }
  // if output is undefined, set it to an empty string
  if (output === undefined) {
    output = '';
  }

  return (
    <GeneralisedBlock
      index={index}
      
      icon={
        <svg
        
        style={{
            marginBottom: '5px',
          }}
        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
</svg>

      }
    >
      <Stack
        spacing={5}
        padding="20px"
        borderRadius={'md'}
        bg={'white'}
        justifyContent={'space-between'}
        direction="column"
        width={'100%'}
        transition={'all 0.19s ease-in-out'}
        display={isCollapsed ? 'none' : 'flex'}
      >
        <Box
          width={'full'}
          minHeight={'10vh'}
          maxHeight={'50vh'}
          mt={'30px'}
          borderRadius={'5px'}
          border={'1px'}
          borderColor={'gray.400'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          padding={'20px 40px'}
          textAlign={output?.length > 200 ? 'start' : 'center'}
          overflow={'auto'}
        >
          <Text
            style={{
              fontWeight: output?.length > 200 ? 'normal' : 'bold',
              // fontsize depending on the length of output
              fontSize:
                output?.length > 200
                  ? ['xs', 'xs', 'xs', 'sm']
                  : ['lg', 'lg', 'xl', '2xl'],
            }}
          >
            {
              typeof output === 'object'
                ? JSON.stringify(output, null, 2).slice(0, 400) + "..."
                : output|| 'Nothing here yet'
            }
          </Text>
        </Box>
      </Stack>
    </GeneralisedBlock>
  );
}
