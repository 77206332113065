import { useUserAuth } from '../util/auth/user-auth';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  InputGroup,
  InputRightElement,
  Container,
  HStack,
  Heading,
  Text,
  InputLeftElement,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Block } from 'baseui/block';
import {
  DisplayMedium,
  HeadingMedium,
  HeadingSmall,
  LabelLarge,
  LabelMedium,
  ParagraphMedium,
  ParagraphSmall,
} from 'baseui/typography';
import { styled, useStyletron, withStyle } from 'baseui';
import { Button } from 'baseui/button';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import { ModalBody } from 'baseui/modal';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Card, StyledBody } from 'baseui/card';
import moment from 'moment';
import { useHTTPRequestContext } from '../util/errors/HTTPProvider';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { LoadingComponent } from '../util/loadingComponent';
import { useCookies } from 'react-cookie';
import { useTime } from 'framer-motion';
import { update } from 'lodash';

const HStackBase = styled('div', props => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    ':not(:last-child)': {
      display: 'block',
      backgroundColor: 'yellow',
      marginBottom: props.$theme.sizing.scale300,
    },
  };
});

export function AppsPage() {
  let { user } = useUserAuth();
  const [apps, setApps] = useState([]);
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const { fetcher, deleter } = useHTTPRequestContext();
  const [loading, setLoading] = useState(true);
  const [spinnerActive, setSpinnerActive] = useState(false);
  const [isTutorialOpen, setIsTutorialOpen] = useState(false);
  const [cookies, setCookie] = useCookies(['tutorial']);

  useEffect(() => {
    // if there is a cookie called firstLogin set to true, then show the tutorial
    if (!cookies.tutorial) {
      setIsTutorialOpen(true);
    }

    if (user) {
      setLoading(true);
      fetcher(`/app/${user.id}/apps/essential`).then(apps => setApps(apps));
      fetcher(`/app/templates`).then(templates => setTemplates(templates));
      setLoading(false);
    }
  }, [user]);

  const deleteApp = async appId => {
    await deleter(`/app/${user.id}/${appId}`);
    fetcher(`/app/${user.id}/apps`).then(apps => setApps(apps));
  };

  const [, theme] = useStyletron();
  return (
    <Block
      display={'flex'}
      width={'100%'}
      flexDirection={'column'}
      alignItems={'center'}
      overrides={{
        Block: {
          style: {
            ':not(:last-child)': {
              display: 'block',
              marginBottom: theme.sizing.scale800,
            },
          },
        },
      }}
    >
      {TutorialScreen(isTutorialOpen, theme, setIsTutorialOpen)}
      <HStackBase>
        <Block
          width={'100%'}
          display={'flex'}
          direction={'row'}
          justifyContent={'space-between'}
          $style={{ marginBottom: theme.sizing.scale800 }}
          alignItems={'center'}
        >
          <Input
            overrides={{
              Root: {
                style: { maxWidth: `calc(${theme.sizing.scale4800} * 4)` },
              },
            }}
            startEnhancer={<Search size="18px" />}
            placeholder="Search"
            value={searchTerm}
            onChange={event => setSearchTerm(event.target.value)}
          />
          <Button size="sm" onClick={() => navigate('/apps/new')}>
            New App
          </Button>
        </Block>

        <FlexGrid
          flexGridColumnCount={3}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          {/* {
                        apps.length === 0 && (
                            <FlexGridItem>
                                <Card title={'No Apps'} overrides={{
                                    Root: {
                                        style: ({$isFocused}) => ({
                                            cursor: "pointer",
                                            ...theme.borders.border600,
                                        })
                                    },
                                    Title: {
                                        style: {
                                            ...theme.typography.LabelLarge,
                                        }
                                    }
                                }}>
                                    <StyledBody >
                                        <ParagraphSmall>
                                            You haven't created any apps yet. Click the New App button to get started.
                                        </ParagraphSmall>
                                    </StyledBody>
                                </Card>
                            </FlexGridItem>
                        )
                    } */}
          {/* if size of apps is 0, then show skeleton*/}
          {apps.length == 0 && (
            <LoadingComponent
              title="No Apps?"
              description="You havent created any apps yet. Click the New App button to get started."
            />
          )}

          {apps.map(app => (
            <FlexGridItem>
              <Button
                size="compact"
                kind="tertiary"
                onClick={() => {
                  if (
                    window.confirm('Are you sure you want to delete this app?')
                  ) {
                    // remove app from list
                    setApps(apps.filter(a => a.id !== app.id));
                    deleteApp(app.id);
                  }
                }}
                overrides={{
                  BaseButton: {
                    style: {
                      position: 'absolute',
                      zIndex: '1',
                      marginTop: '-8px',
                      marginLeft: '-8px',
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      backgroundColor: '#c4c4c4',
                    },
                  },
                }}
              >
                X
              </Button>
              <Card
                title={app.name}
                onClick={() => navigate(`/apps/${app.id}`)}
                overrides={{
                  Root: {
                    style: ({ $isFocused }) => ({
                      cursor: 'pointer',
                      ...theme.borders.border600,
                    }),
                  },
                  Title: {
                    style: {
                      ...theme.typography.LabelLarge,
                    },
                  },
                }}
              >
                <StyledBody>
                  <ParagraphSmall>
                    modified {moment().diff(moment(app.updatedAt), 'days')} days
                    ago
                  </ParagraphSmall>
                </StyledBody>
              </Card>
            </FlexGridItem>
          ))}
        </FlexGrid>
        {/* LINE*/}
        <Block
          width={'100%'}
          height={'1px'}
          backgroundColor={'#E0E0E0'}
          marginTop={'40px'}
        />
        {/* HEADING*/}
        <Block
          width={'100%'}
          marginTop={'10px'}
          marginBottom={'10px'}
          display={'flex'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <HeadingSmall>Example Apps</HeadingSmall>
        </Block>

        {/* flex grid that shows apps like the previous one */}
        <FlexGrid
          flexGridColumnCount={3}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          {templates.map(template => (
            <FlexGridItem>
              <Card
                title={template.name}
                onClick={() => navigate(`/apps/${template.id}`)}
                overrides={{
                  Root: {
                    style: ({ $isFocused }) => ({
                      cursor: 'pointer',
                      ...theme.borders.border600,
                    }),
                  },
                  Title: {
                    style: {
                      ...theme.typography.LabelLarge,
                    },
                  },
                }}
              >
                <StyledBody>
                  <ParagraphSmall>
                    modified {moment().diff(moment(template.updatedAt), 'days')}{' '}
                    days ago
                  </ParagraphSmall>
                </StyledBody>
              </Card>
            </FlexGridItem>
          ))}
        </FlexGrid>
      </HStackBase>
    </Block>
  );
}

function TutorialScreen(isTutorialOpen, theme, setIsTutorialOpen) {
  const [currentStep, setCurrentStep] = useState(1);

  if (!isTutorialOpen) return null;

  return (
      <Container
      top={0}
      left={0}
      maxW={'100vw'}
      maxH={'100vh'}
      // blur background
      backgroundColor={'rgba(0,0,0,0.5)'}
      backdropFilter={'blur(3px)'}
      zIndex={100}
      height="100vh"
      width="100vw"
      position={'absolute'}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {/* screen depending on currentstep */}
      {currentStep == 1 && (
        <ScreenOne
          theme={theme}
          setCurrentStep={setCurrentStep}
          setIsTutorialOpen={setIsTutorialOpen}
        ></ScreenOne>
      )}
      {currentStep == 2 && (
        <ScreenTwo
          theme={theme}
          setCurrentStep={setCurrentStep}
          setIsTutorialOpen={setIsTutorialOpen}
        />
      )}
      {currentStep == 3 && (
        <ScreenThree
          theme={theme}
          setCurrentStep={setCurrentStep}
          setIsTutorialOpen={setIsTutorialOpen}
        />
      )}
    </Container>
  );


}
const ScreenOne = ({ theme, setCurrentStep, setIsTutorialOpen }) => {
  const INTEGRATION_ITEMS = [
    {
      key: 'open_ai',
      logo: 'logos/openai.svg',
      purpose: 'Language Models',
    },
    {
      key: 'serp',
      logo: 'serpapi-logo.png',
      purpose: 'Google Search',
    },
    {
      key: 'browserless',
      logo: 'logos/browserless.svg',
      purpose: 'Webscraping',
    },
  ];

  let { user } = useUserAuth();
  const toast = useToast();
  const { patcher } = useHTTPRequestContext();

  const openAIKey = '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';
  const serpKey = '3b42de40003cccdb59929e030a34876beac4518d3c8260165b5c9cb520809619';
  const browserlessKey = '020a2764-a38e-482a-b460-05cbe9368eb0';

  const seedValues = INTEGRATION_ITEMS.reduce((acc, currentValue) => {
    acc[currentValue.key] = {
      secret: null,
    };
    return acc;
  }, {});

  const integrations = {
    open_ai: {
      secret: openAIKey,
    },
    serp: {
      secret: serpKey,
    },
    browserless: {
      secret: browserlessKey,
    },
  };

  const saveKeys = async () => {
    await patcher(`/settings/${user.id}/integrations`, {
      integrations: integrations,
    }).then(res => {
      toast({
        title: 'Successfully activated test keys',
      });
    });
  };

  const updateIntegrations = ({ openaiKey, serpKey, browserlessKey }) => {
    // console.log(openaiKey, serpKey, browserlessKey);

    saveKeys();
  };

  return (
    <Block
      backgroundColor={'white'}
      display={'flex'}
      width={'30%'}
      minWidth={'300px'}
      height={'70%'}
      padding={'30px'}
      minHeight={'400px'}
      flexDirection={'column'}
      alignItems={'start'}
      justifyContent={'center'}
      // rounded borders
      overrides={{
        Block: {
          style: {
            borderRadius: theme.borders.radius400,
          },
        },
      }}
    >
      <HeadingMedium marginBottom="scale800">Welcome to Agent!</HeadingMedium>
      <ParagraphMedium marginBottom="scale800">
        Agent allows you to create powerful apps with Large Language Models by
        chaining various APIs
      </ParagraphMedium>

      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '0',
          paddingBottom: '56.25%',
        }}
        dangerouslySetInnerHTML={{
          __html:
            '<iframe src="https://www.loom.com/embed/44c858e15e874f48ad3e653a233a6d54" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>',
        }}
      />

      {/*  */}
      <Button
        overrides={{
          BaseButton: {
            style: {
              marginTop: 'auto',
              width: '100%',
            },
          },
        }}
        onClick={() => {
          updateIntegrations({
            openAIKey,
            serpKey,
            browserlessKey,
          });
          document.cookie = 'tutorial=false';
          setIsTutorialOpen(false);
        }}
      >
        Create your first app
      </Button>
    </Block>
  );
};

const ScreenTwo = ({ theme, setCurrentStep, setIsTutorialOpen }) => {
  const INTEGRATION_ITEMS = [
    {
      key: 'open_ai',
      logo: 'logos/openai.svg',
      purpose: 'Language Models',
    },
    {
      key: 'serp',
      logo: 'serpapi-logo.png',
      purpose: 'Google Search',
    },
    {
      key: 'browserless',
      logo: 'logos/browserless.svg',
      purpose: 'Webscraping',
    },
  ];

  let { user } = useUserAuth();
  const toast = useToast();
  const { patcher } = useHTTPRequestContext();

  const [openaiKey, setOpenaiKey] = useState('');
  const [browserlessKey, setBrowserlessKey] = useState('');
  const [serpKey, setSerpKey] = useState('');

  const seedValues = INTEGRATION_ITEMS.reduce((acc, currentValue) => {
    acc[currentValue.key] = {
      secret: null,
    };
    return acc;
  }, {});

  const [integrations, setIntegrations] = useState(seedValues);

  const saveKeys = async () => {
    await patcher(`/settings/${user.id}/integrations`, {
      integrations: integrations,
    }).then(res => {
      toast({
        title: 'Successfully updated the keys',
      });
    });
  };

  const updateIntegrations = ({ openaiKey, serpKey, browserlessKey }) => {
    // console.log(openaiKey, serpKey, browserlessKey);
    setIntegrations({
      ...integrations,
      open_ai: {
        secret: openaiKey,
      },
      serp: {
        secret: serpKey,
      },
      browserless: {
        secret: browserlessKey,
      },
    });
    saveKeys();
  };

  useEffect(() => {
    setIntegrations({
      ...integrations,
      open_ai: {
        secret: openaiKey,
      },
      serp: {
        secret: serpKey,
      },
      browserless: {
        secret: browserlessKey,
      },
    });
  }, [openaiKey, serpKey, browserlessKey]);

  return (
    <Block
      backgroundColor={'white'}
      display={'flex'}
      width={'30%'}
      minWidth={'300px'}
      height={'70%'}
      minHeight={'650px'}
      flexDirection={'column'}
      alignItems={'start'}
      justifyContent={'center'}
      padding={'30px'}
      // rounded borders
      overrides={{
        Block: {
          style: {
            borderRadius: theme.borders.radius400,
          },
        },
      }}
    >
      <HeadingMedium marginBottom="scale800">API Key Setup</HeadingMedium>
      <ParagraphMedium marginBottom="scale800">
        You'll need to set up a few API keys to get started. Keys are encrypted
        and stored in secure enclaves and are only used for executing your apps.
      </ParagraphMedium>

      <Block
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
        marginTop="scale800"
      >
        <LabelMedium marginBottom="scale400">OpenAI API Key</LabelMedium>
        <Input
          type="password"
          overrides={{
            Input: {
              style: {
                width: '100%',
              },
            },
          }}
          placeholder="Enter your OpenAI API Key"
          onChange={e => setOpenaiKey(e.target.value)}
          value={
            // if company key then dont show
            openaiKey === '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'
              ? 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
              : openaiKey
          }
        />
      </Block>
      <Block
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
        marginTop="scale800"
      >
        <LabelMedium marginBottom="scale400">Serp API Key</LabelMedium>
        <Input
          type="password"
          overrides={{
            Input: {
              style: {
                width: '100%',
              },
            },
          }}
          placeholder="Enter your Serp API Key"
          onChange={e => setSerpKey(e.target.value)}
          value={
            // if company key then dont show
            serpKey ===
            '3b42de40003cccdb59929e030a34876beac4518d3c8260165b5c9cb520809619'
              ? 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
              : serpKey
          }
        />
      </Block>
      <Block
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
        marginTop="scale800"
      >
        <LabelMedium marginBottom="scale400">Browserless API Key</LabelMedium>
        <Input
          type="password"
          overrides={{
            Input: {
              style: {
                width: '100%',
              },
            },
          }}
          placeholder="Enter your Browserless API Key"
          value={
            // if company key then dont show
            browserlessKey === '020a2764-a38e-482a-b460-05cbe9368eb0'
              ? 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
              : browserlessKey
          }
          onChange={e => setBrowserlessKey(e.target.value)}
        />
      </Block>

      {/*  */}
      <Text
        marginTop="20px"
        width="100%"
        align={'center'}
        cursor={'pointer'}
        onClick={() => {
          setBrowserlessKey('020a2764-a38e-482a-b460-05cbe9368eb0');
          setSerpKey(
            '3b42de40003cccdb59929e030a34876beac4518d3c8260165b5c9cb520809619'
          );
          setOpenaiKey('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');
        }}
      >
        Or use test keys{' '}
      </Text>
      <Button
        overrides={{
          BaseButton: {
            style: {
              marginTop: 'auto',
              width: '100%',
            },
          },
        }}
        onClick={() => {
          if (openaiKey && serpKey && browserlessKey) {
            updateIntegrations({ openaiKey, serpKey, browserlessKey });
            setCurrentStep(3);
          } else {
            toast({
              title: 'Please input all the keys',
            });
          }
        }}
      >
        Continue
      </Button>
    </Block>
  );
};
const ScreenThree = ({ theme, setCurrentStep, setIsTutorialOpen }) => {
  return (
    <Block
      backgroundColor={'white'}
      display={'flex'}
      width={'30%'}
      minWidth={'300px'}
      height={'70%'}
      overflowY={'scroll'}
      minHeight={'650px'}
      flexDirection={'column'}
      alignItems={'start'}
      justifyContent={'center'}
      padding={'30px'}
      overrides={{
        Block: {
          style: {
            borderRadius: theme.borders.radius400,
          },
        },
      }}
    >
      <HeadingMedium marginBottom="scale800">You're all done!</HeadingMedium>
      <ParagraphMedium marginBottom="scale800">
        We're excited to have you on board. Agent is currently in beta, and it's
        going to keep getting better every day. There might be a few bugs here
        and there. If you find any, please let us know in the Discord server.
      </ParagraphMedium>
      
      <Button
        overrides={{
          BaseButton: {
            style: {
              marginTop: 'auto',
              width: '100%',
            },
          },
        }}
        onClick={() => {
          // set cookiee
          document.cookie = 'tutorial=false';
          setIsTutorialOpen(false);
        }}
      >
        Finish
      </Button>
    </Block>
  );
};
