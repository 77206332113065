import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../../util/auth/user-auth';
import { useAppContext } from '../../util/app/app-provider';
import { poster } from '../../util/requests/axios';

import {
  Text,
  Input,
  Button,
  useDisclosure,
  Box,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  HStack,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useFormik } from 'formik';
import Mustache from 'mustache';
import ResizeTextarea from 'react-textarea-autosize';
import { ResultComponent } from './result';
import { AvailableVariables } from './available-variables';
import { interpolate } from '../../util/interpolate';
import { useHTTPRequestContext } from '../../util/errors/HTTPProvider';
import { Button as BaseButton } from 'baseui/button';
import { GeneralisedBlock } from '../GeneralisedBlock';
import { BlockTypes } from '../../util/constants';

export function HttpBlock({ index }) {
  let { user } = useUserAuth();
  const { poster } = useHTTPRequestContext();
  let { blocks, setBlockRunResult, deleteBlock, setBlockData, input } =
    useAppContext();
  const result = blocks[index]?.result;
  const processedHeaders = blocks[index]?.headers?.split(';').map(header => {
    const [key, value] = header.split(/:(.+)/);

    return { key, value };
  });

  console.log(processedHeaders)

  const [headers, setHeaders] = useState(processedHeaders ||
    [
    { key: 'Authorization', value: 'Bearer' },
    { key: 'Content-Type', value: 'application/json' },
  ]); 
  const { isOpen: isConfigOpen, onToggle: onConfigToggle } = useDisclosure();

  const options = [
    {
      label: 'HTTP Method',
      options: [
        { label: 'GET', value: 'GET' },
        { label: 'POST', value: 'POST' },
        { label: 'PUT', value: 'PUT' },
        { label: 'PATCH', value: 'PATCH' },
        { label: 'DELETE', value: 'DELETE' },
      ],
    },
  ];

  const formik = useFormik({
    initialValues: {
      method: blocks[index]?.method,
      url: blocks[index]?.url,
      body: blocks[index]?.body,
    },
    onSubmit: async values => {
      const httpRequestData = {
        type: blocks[index].type,
        method: values.method,
        url: interpolate(values.url, {
          blocks,
          input,
        }),
      };

      // get headers from headers state
      httpRequestData.headers = {};
      headers.forEach(header => {
        httpRequestData.headers[header.key] = interpolate(header.value, {
          blocks,
          input,
        });
      });

      if (values.body) {
        try {
          httpRequestData.body = JSON.parse(values.body);
          // interpolate
          for (let key in httpRequestData.body) {
            httpRequestData.body[key] = interpolate(httpRequestData.body[key], {
              blocks,
              input,
            });
          }
        } catch (e) {
          console.error(`Cannot parse http request body JSON: ${e}`);
        }
      }

      // console.log('http request data');
      console.log(httpRequestData);

      const blockRunResult = await poster(
        `/app/${user.id}/block/run`,
        httpRequestData
      );
      setBlockRunResult(BlockTypes.HTTP, index, blockRunResult);
    },
  });

  const setBlockDataAndSave = () => {
    const httpBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, httpBlockData);
  };

  useEffect(() => {
    const httpBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, httpBlockData);
  }, [formik.values]);

  useEffect(() => {
    // headers
    const headersString = headers
      .map(header => `${header.key}:${header.value}`)
      .join(';');
    formik.setFieldValue('headers', headersString);
  }, [headers]);


  return (
    <GeneralisedBlock
      index={index}
      onRun={formik.handleSubmit}
      isLoading={formik.isSubmitting}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          style={{ marginBottom: '5px' }}
        >
          <path
            fill-rule="evenodd"
            d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
          />
        </svg>
      }
    >
      <Stack
        spacing={5}
        padding="20px"
        bg={'white'}
        justifyContent={'space-between'}
        direction="column"
      >
        {/* <AvailableVariables index={index} /> */}
        <Stack
          direction={'row'}
          space={3}
          as={'button'}
          onClick={onConfigToggle}
          align="center"
        >
          <Text>Configuration</Text>
          {isConfigOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Stack>
        <Stack
          direction="row"
          bg="white"
          padding={10}
          style={{ display: isConfigOpen ? 'flex' : 'none' }}
        >
          <FormControl>
            <FormLabel>HTTP Method</FormLabel>
            <Select
              id="method"
              options={options}
              useBasicStyles
              onChange={event => {
                formik.setFieldValue('method', event.value);
              }}
            />
          </FormControl>
        </Stack>
        <FormControl>
          <FormLabel>URL</FormLabel>
          <Input
            id="url"
            placeholder="URL"
            onChange={event => {
              formik.handleChange(event);
            }}
            value={formik.values.url}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Headers</FormLabel>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Key</Th>
                <Th>Value</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(() => {
                // for key in headers
                return headers.map((header, index) => {
                  return (
                    <Tr key={index}>
                      <Td>
                        <Input
                          id="key"
                          placeholder="Key"
                          onChange={event => {
                            const newHeaders = [...headers];
                            newHeaders[index].key = event.target.value;
                            setHeaders(newHeaders);
                          }}
                          value={header.key}
                        />
                      </Td>
                      <Td>
                        <Input
                          id="value"
                          placeholder="Value"
                          onChange={event => {
                            const newHeaders = [...headers];
                            newHeaders[index].value = event.target.value;
                            setHeaders(newHeaders);
                          }}
                          value={header.value}
                        />
                      </Td>
                      {/* delete button */}
                      <Td>
                        <Button
                          onClick={() => {
                            const newHeaders = [...headers];
                            newHeaders.splice(index, 1);
                            setHeaders(newHeaders);
                          }}
                        >
                          Delete
                        </Button>
                      </Td>
                      
                    </Tr>
                  );
                }
                );
              })()}
            </Tbody>
          </Table>
          {/* button for adding more headers */}
          <Button
            onClick={() => {
              setHeaders([...headers, { key: null, value: '' }]);
            }}
          >
            Add Header
          </Button>

        </FormControl>
        <FormControl>
          <FormLabel>Body</FormLabel>
          <Textarea
            as={ResizeTextarea}
            id="body"
            placeholder='{
              "title":"${blocks[0].input}",
              "body": "test"
          }'
            onChange={event => {
              formik.handleChange(event);
            }}
            value={formik.values.body}
          />
        </FormControl>
        {(() => {
          const props = {
            result,
            index,
          };
          return <ResultComponent {...props} />;
        })()}
      </Stack>
    </GeneralisedBlock>
  );
}
