import React from 'react';
import { useState, useEffect } from 'react';
import { useAppContext } from '../util/app/app-provider';
import { useHTTPRequestContext } from '../util/errors/HTTPProvider';
import { useUserAuth } from '../util/auth/user-auth';

import {
  Box,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  Flex,
} from '@chakra-ui/react';
// import toast
import { useToast } from '@chakra-ui/react';
// import SPinner
import { Spinner } from '@chakra-ui/react';

import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
import posthog from 'posthog-js';

export function UIDeploy() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const { appId, blocks } = useAppContext();
  const { poster, fetcher } = useHTTPRequestContext();
  let { user } = useUserAuth();
  const [isDeploying, setIsDeploying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasDeployed, setHasDeployed] = useState(false);
  const [numInputs, setNumInputs] = useState(1);

  const [inputs, setInputs] = useState(
    // create a object with title and placeholder
    // Array.from({ length: numInputs}, (_, i) => ({
    //   title: '',
    //   placeholder: '',
    // }))
[]


    ); //JSONB
  const toast = useToast();

  useState(() => {
    posthog.capture('user_deployment_page_opened');
    if (user) {
      // get UI spinups from fetch
      setIsLoading(true);
      fetcher(`app/${user.id}/${appId}/deploy`).then(res => {
        // if res is null then set all to empty
        if (!res) {
          setName('');
          setDescription('');
          setTitle('');
          setPlaceholder('');

          setIsLoading(false);
          setHasDeployed(false);
          setNumInputs(blocks[0].inputs.length)
          return;
        }
        console.log(res);
        setName(res.name);
        setDescription(res.description);
        setTitle(res.input_title);
        setPlaceholder(res.input_placeholder);
        setNumInputs(blocks[0]?.inputs.length);
        setInputs(JSON.parse(res.input_details) || [{title: res.input_title , placeholder: res.input_placeholder}]);
        // setTitle and setPlaceholder from res to inputs

        setHasDeployed(true);
        setIsLoading(false);
      });
    }
  }, [user]);

  useEffect(() => {
    console.log(inputs);
  }, [inputs]);

  return (
    <Box
      textAlign="start"
      fontSize="xl"
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      fontFamily={'Inter'}
    >
      <Box
        width={['100%', '100%', '100%', '70%']}
        padding={10}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        {/* spinner if loading */}
        {isLoading && <Spinner></Spinner>}
        <Stack width={'full'} spacing={0}>
          {/* full width hstack saying your app is live */}
          {hasDeployed && (
            <Stack
              width={'full'}
              spacing={0}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              padding={'10px 10px 10px 20px'}
              direction={'row'}
              // green bg
              bg={'#E6F4EA'}
              // darker green border
              border={'1px solid #A7D8C2'}
              borderRadius={'10px'}
              marginBottom={'20px'}
            >
              <Text fontFamily={'Inter'} fontSize={['sm', 'md', 'md', 'md']}>
                Your app is live at{' '}
                <span
                  style={{
                    // fontweight
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    // go to link in new tab
                    window.open(
                      `https://app.gptagent.com/share/${appId}`,
                      '_blank'
                    );
                  }}
                >
                  https://app.gptagent.com/share/{appId}
                </span>
              </Text>
              <Button
                // navigate to the app
                // style
                bg={'#A7D8C2'}
                color={'black'}
                transition={'all 0.2s ease-in-out'}
                _hover={{
                  bg: '#A7D8C2',
                }}
                _active={{
                  transform: 'scale(0.95)',
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://app.gptagent.com/share/${appId}`
                  );
                  toast({
                    title: 'Link copied to clipboard',
                    status: 'success',
                    duration: 2000,
                    position: 'top',
                  });
                }}
              >
                <HStack>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-link"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                    <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                  </svg>
                  <Text> Copy link</Text>
                </HStack>
              </Button>
            </Stack>
          )}
        </Stack>

        <Stack width={'full'} marginTop={'5%'} spacing={'30px'}>
          <FormControl>
            <FormLabel
              disabled={isDeploying || isLoading}
              style={{
                fontWeight: 'bold',
                fontSize: '2xl',
              }}
            >
              Give your app a name{' '}
            </FormLabel>
            <Input
              disabled={isDeploying || isLoading}
              resize={'none'}
              padding={'15px'}
              borderRadius={'10px'}
              placeholder="Natural Language to SQL"
              value={name}
              onChange={e =>
                e.target.value.length < 50 && setName(e.target.value)
              }
              style={{
                border: '1px solid #C7C7C7',
                '&::placeholder': {
                  color: '#C7C7C7',
                },
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel
              disabled={isDeploying || isLoading}
              style={{
                fontWeight: 'bold',
                fontSize: '2xl',
              }}
            >
              Write a short description.{' '}
            </FormLabel>
            <Input
              disabled={isDeploying || isLoading}
              value={description}
              onChange={e =>
                // if length is less than 100, set description
                e.target.value.length < 150 && setDescription(e.target.value)
              }
              resize={'none'}
              padding={'15px'}
              borderRadius={'10px'}
              placeholder="A powerful tool that uses GPT-3 to generate SQL from plain English"
              style={{
                border: '1px solid #C7C7C7',
                '&::placeholder': {
                  color: '#C7C7C7',
                },
              }}
            />
          </FormControl>
          <FormControl>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: '2xl',
              }}
              marginBottom={'10px'}
            >
             Edit Input Details{' '}
            </Text>
            {/* for each num_inputs */}
            {Array.from(Array(numInputs).keys()).map((i, index) => (
              <Stack>
            <Text
            style={{
              fontWeight: 'bold',
              fontSize: '2xl',
            }}
            marginBottom={'10px'}
            marginTop={'20px'}

            >Input {i+1}</Text>
            <Flex
              direction={'column'}
              justifyContent={'space-between'}
              width={'full'}
            >
              <Flex
                direction={'col'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                width={'full'}

              >
                <FormLabel
                width={'10%'}
                  style={{
                    fontSize: '2xl',
                  }}
                >
                  Title{' '}
                </FormLabel>
                <Input
                  disabled={isDeploying || isLoading}
                  value={
                    // check if inputs[i] exists
                    inputs[i]?.title
                  }
                  onChange={e =>{
                    // setinputs [i]
                    // if the index doesnt exist, create it
                    if(!inputs[i]){
                      let newInput = [...inputs];
                      newInput[i] = {
                        title: e.target.value,
                        placeholder: '',
                        description: '',
                      }
                      setInputs(newInput);
                    }
                    else{
                      let newInput = [...inputs];
                      newInput[i].title = e.target.value;
                      setInputs(newInput);
                    }
                  }

                  }
                  resize={'none'}
                  padding={'15px'}
                  borderRadius={'10px'}
                  marginBottom={'10px'}
                  placeholder="Enter your query in plain English"
                  style={{
                    border: '1px solid #C7C7C7',
                    '&::placeholder': {
                      color: '#C7C7C7',
                    },
                  }}
                />
              </Flex>
              <Flex

              direction={'col'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              width={'full'}

              >
                <FormLabel
                  style={{
                    fontSize: '2xl',
                  }}
                >
                  Placeholder{' '}
                </FormLabel>
                <Input
                  disabled={isDeploying || isLoading}
                  value={inputs[i]?.placeholder}
                  onChange={e =>
                    {
                      let newInput = [...inputs];
                      newInput[i].placeholder = e.target.value;
                      setInputs(newInput);
                      console.log(inputs);
                    }
                  }
                  resize={'none'}
                  padding={'15px'}
                  borderRadius={'10px'}
                  placeholder="Fetch the last 100 records from the users table"
                  style={{
                    border: '1px solid #C7C7C7',
                    '&::placeholder': {
                      color: '#C7C7C7',
                    },
                  }}
                />
              </Flex>
            </Flex>

            </Stack>
            ))}
          </FormControl>

          <Button
            disabled={isDeploying || isLoading}
            width={'full'}
            bg={'black'}
            color={'white'}
            borderRadius={'10px'}
            py={6}
            _hover={{
              bg: 'gray.800',
            }}
            _active={{
              transform: 'scale(0.98)',
            }}
            onClick={async () => {
              setIsDeploying(true);
              console.log(inputs);
              await poster(`app/${user.id}/${appId}/deploy`, {
                id: appId,
                name: name,
                description: description,
                input_title: title,
                input_placeholder: placeholder,
                num_inputs: numInputs,
                input_details: JSON.stringify(inputs),
              });
              setHasDeployed(true);
              setIsDeploying(false);
              toast({
                title: 'App Deployed Successfully!',
                position: 'top',
              });
            }}
          >
            {isDeploying ? <Counter></Counter> : 'Deploy'}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

function Counter() {
  const [seconds, setSeconds] = useState(0);
  const [milliseconds, setMilliseconds] = useState(0);

  useEffect(() => {
    let interval = null;
    let startTime = performance.now();
    interval = setInterval(() => {
      const currentTime = performance.now();
      const deltaTime = currentTime - startTime;
      startTime = currentTime;
      setSeconds(seconds => seconds + Math.floor(deltaTime / 1000));
      setMilliseconds(milliseconds => milliseconds + (deltaTime % 1000));
    }, 10);
    return () => clearInterval(interval);
  }, []);

  return (
    <h1>
      deploying... {Math.floor(milliseconds / 100) / 10}
      {Math.floor(milliseconds / 100) % 10 === 0 ? '.0' : ''} s
    </h1>
  );
}
