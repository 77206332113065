import React, { useEffect, useState, useMemo } from 'react';
import { useUserAuth } from '../../util/auth/user-auth';
import { useAppContext } from '../../util/app/app-provider';
import { poster } from '../../util/requests/axios';
import {
  FormLabel,
  FormControl,
  Button,
  HStack,
  Box,
  Heading,
  Stack,
  Input,
  Textarea,
  Toast,
  Flex,
} from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';
import { ResultComponent } from './result';
import debounce from 'lodash.debounce';
import { AvailableVariables } from './available-variables';
import { useHTTPRequestContext } from '../../util/errors/HTTPProvider';
import { Button as BaseButton } from 'baseui/button';
import { useToast } from '@chakra-ui/react';
import { GeneralisedBlock } from '../GeneralisedBlock';
import { SelectBlockComponent } from '../prev_variables';
import { BlockTypes } from '../../util/constants';

export function InputBlock({ index }) {
  let { user } = useUserAuth();
  const { poster } = useHTTPRequestContext();
  let { blocks, setBlockRunResult, deleteBlock, setBlockData, input } =
    useAppContext();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toast = useToast();
  const [inputs, setInputs] = useState(blocks[index]?.inputs || ['']);

  const [areVariablesVisible, setAreVariablesVisible] = useState(false);

  useEffect(() => {
    const inputBlockData = {
      ...blocks[index],
      inputs: inputs,
    };
    setBlockData(index, inputBlockData);
  }, [inputs]);

  return (
    <GeneralisedBlock
      index={index}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          style={{
            marginBottom: '5px',
          }}
        >
          <path d="M10 5h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4v1h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4v1zM6 5V4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4z" />
          <path
            fill-rule="evenodd"
            d="M8 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13A.5.5 0 0 1 8 1z"
          />
        </svg>
      }
    >
      <Stack
        spacing={5}
        padding="20px"
        borderRadius={'md'}
        bg={'white'}
        justifyContent={'space-between'}
        direction="column"
        width={'100%'}
        transition={'all 0.19s ease-in-out'}
        display={isCollapsed ? 'none' : 'flex'}
      >
        {blocks[index].inputs?.map((input, index) => {
          return (
            <Flex>
              <FormControl>
                <Flex
                  h={'40px'}
                  // center
                  justify={'center'}
                  alignItems={'center'}
                >
                  <FormLabel
                  marginTop={'10px'}
                  >{index + 1}</FormLabel>
                  <Input
                    // as={ResizeTextarea}
                    fontFamily={'monospace'}
                    id="input"
                    placeholder="Enter Input here..."
                    onChange={event => {
                      const newInputs = [...inputs];
                      newInputs[index] = event.target.value;
                      setInputs(newInputs);
                    }}
                    value={inputs[index]}
                    onClick={() => {
                      setAreVariablesVisible(true);
                    }}
                  />
                  {/* {areVariablesVisible && (
                <SelectBlockComponent index={index} blocks={blocks} onHide={
                  () => {
                    setAreVariablesVisible(false);
                  }
                }/>
              )} */}
                  <Flex
                  marginLeft={'10px'}
                  gap={'4px'}
                  >
                    <BaseButton
                      // style={{ marginTop: '10px' }}
                      size="compact"
                      kind={'secondary'}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          '${blocks[0].inputs[' + index + ']}'
                        );
                        toast({
                          title: 'Reference copied to clipboard',
                          position: 'top',
                        });
                      }}
                    >
                      Copy
                    </BaseButton>
                    <BaseButton
                      // style={{ marginTop: '10px' }}
                      size="compact"
                      kind={'primary'}
                      onClick={() => {
                        const newInputs = [...inputs];
                        newInputs.splice(index, 1);
                        setInputs(newInputs);
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
</svg>
                    </BaseButton>
                  </Flex>
                </Flex>
                {/* plus button */}
              </FormControl>
            </Flex>
          );
        })}
        <Flex
          h={'40px'}
          // center
          justify={'start'}
          alignItems={'center'}
        >
          <Button
            style={{ marginTop: '10px' }}
            bg={'#F5F5F5'}
            color={'#000000'}
            onClick={() => {
              const newInputs = [...inputs];
              newInputs.push('');
              setInputs(newInputs);
            }}
          >
            Add input +
          </Button>
        </Flex>
      </Stack>
    </GeneralisedBlock>
  );
}
