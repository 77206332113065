import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../../util/auth/user-auth';
import { useAppContext } from '../../util/app/app-provider';
import {
  Input,
  Button,
  Box,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  HStack,
  Textarea,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import Mustache from 'mustache';
import ResizeTextarea from 'react-textarea-autosize';
import { ResultComponent } from './result';
import { AvailableVariables } from './available-variables';
import { interpolate } from '../../util/interpolate';
import { useHTTPRequestContext } from '../../util/errors/HTTPProvider';
import { Button as BaseButton } from 'baseui/button';
import { GeneralisedBlock } from '../GeneralisedBlock';
import { SelectBlockComponent } from '../prev_variables';
import { BlockTypes } from '../../util/constants';

export function SlackBlock({ index }) {
  const [areVariablesVisible, setAreVariablesVisible] = useState(false);
  let { user } = useUserAuth();
  let { blocks, setBlockRunResult, deleteBlock, setBlockData, input } =
    useAppContext();
  const result = blocks[index].result;

  const pruneDiscordResult = res => {
    if (res.code) {
      return res; //error
    }
    return { status: res.status }; //just return the status if success
  };

  const { poster } = useHTTPRequestContext();

  const formik = useFormik({
    initialValues: {
      webhookURL: blocks[index]?.webhookURL,
      message: blocks[index]?.message,
    },
    onSubmit: async values => {
      const blockRunResult = await poster(`/app/${user.id}/block/run`, {
        type: blocks[index].type,
        webhookURL: values.webhookURL,
        message: interpolate(values.message, { blocks, input }),
      });
      setBlockRunResult(BlockTypes.Slack, index, pruneDiscordResult(blockRunResult));
    },
  });

  useEffect(() => {
    const searchBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, searchBlockData);
  }, [formik.values]);

  const setBlockDataAndSave = async () => {
    const searchBlockData = {
      ...blocks[index],
      ...formik.values,
    };
    setBlockData(index, searchBlockData);
  };

  return (
    <GeneralisedBlock
      index={index}
      onRun={formik.handleSubmit}
      isLoading={formik.isSubmitting}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="20px"
          height="20px"
          style={{ marginBottom: '5px' }}
        >
          {' '}
          <path d="M 11.5 3 C 10.119 3 9 4.119 9 5.5 C 9 6.881 10.119 8 11.5 8 L 14 8 L 14 5.5 C 14 4.119 12.881 3 11.5 3 z M 17.5 3 C 16.119 3 15 4.119 15 5.5 L 15 11.5 C 15 12.881 16.119 14 17.5 14 C 18.881 14 20 12.881 20 11.5 L 20 5.5 C 20 4.119 18.881 3 17.5 3 z M 5.5 9 C 4.119 9 3 10.119 3 11.5 C 3 12.881 4.119 14 5.5 14 L 11.5 14 C 12.881 14 14 12.881 14 11.5 C 14 10.119 12.881 9 11.5 9 L 5.5 9 z M 23.5 9 C 22.119 9 21 10.119 21 11.5 L 21 14 L 23.5 14 C 24.881 14 26 12.881 26 11.5 C 26 10.119 24.881 9 23.5 9 z M 5.5 15 C 4.119 15 3 16.119 3 17.5 C 3 18.881 4.119 20 5.5 20 C 6.881 20 8 18.881 8 17.5 L 8 15 L 5.5 15 z M 11.5 15 C 10.119 15 9 16.119 9 17.5 L 9 23.5 C 9 24.881 10.119 26 11.5 26 C 12.881 26 14 24.881 14 23.5 L 14 17.5 C 14 16.119 12.881 15 11.5 15 z M 17.5 15 C 16.119 15 15 16.119 15 17.5 C 15 18.881 16.119 20 17.5 20 L 23.5 20 C 24.881 20 26 18.881 26 17.5 C 26 16.119 24.881 15 23.5 15 L 17.5 15 z M 15 21 L 15 23.5 C 15 24.881 16.119 26 17.5 26 C 18.881 26 20 24.881 20 23.5 C 20 22.119 18.881 21 17.5 21 L 15 21 z" />
        </svg>
      }
    >
      <Stack width={'100%'} direction="row">
        <Stack
          spacing={5}
          padding="20px"
          bg={'white'}
          justifyContent={'space-between'}
          direction="column"
          width={'100%'}
        >
          {/* <AvailableVariables index={index} /> */}
          <FormControl>
            <FormLabel>Webhook URL</FormLabel>
            <Input
              id="webhookURL"
              placeholder="https://hooks.slack.com/..."
              onChange={event => {
                formik.handleChange(event);
              }}
              value={formik.values.webhookURL}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Message</FormLabel>
            <Textarea
              as={ResizeTextarea}
              id="message"
              placeholder="Message"
              onChange={event => {
                formik.handleChange(event);
              }}
              onClick={() => {
                setAreVariablesVisible(true);
              }}
              
              value={formik.values.message}
            />
             {areVariablesVisible && (
                <SelectBlockComponent index={index} blocks={blocks} onHide={
                  () => {
                    setAreVariablesVisible(false);
                  }
                }/>
              )}
          </FormControl>
        </Stack>
        <Box width={'100%'}>
          <ResultComponent {...{ result, index }} />
        </Box>
      </Stack>
    </GeneralisedBlock>
  );
}
